import { useNavigate } from 'react-router-dom';
import Footer from '../../../components/Footer';
import { Layout } from '../../../components/layout';
import ButtonBack from '../../../components/ui/buttonBack';

export const Rules = () => {
	const navigate = useNavigate();

	return (
		<Layout customBG="bg-background-dark" logoWhite>
			<div className="px-3.5 pt-7 pb-7 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between px-3.5 pt-10 pb-6 lg:flex-col lg:justify-start lg:items-start lg:gap-4">
					<ButtonBack classNameIcon="text-custom-white" />
					<p className="text-xl font-semibold text-center font-bahnschrift text-custom-white lg:text-primary lg:text-left">
						Regulamento
					</p>
					<div></div>
				</div>

				<div className="text-sm text-left text-custom-gray4 font-bahnschrift lg:rounded-lg lg:bg-custom-gray2 lg:p-8">
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							1. Descrição do Torneio
						</span>
					</p>
					<br />
					O FUTPro é um desafio de fantasy sports com “Perguntas e
					Respostas" para que cada participante mostre suas
					habilidades e conhecimentos conforme seus times favoritos ou
					sua expertise em esportes. O game recompensa os jogadores
					com base em sua habilidade e precisão nas respostas.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							2. Elegibilidade
						</span>
					</p>
					<br />
					Podem participar do torneio pessoas maiores de 18 anos com
					residência no Brasil. Funcionários da FutPro e seus
					familiares diretos estão proibidos de participar.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							3. Como Participar
						</span>
					</p>
					<br />
					- Os usuários devem se inscrever a cada torneio que desejem
					participar utilizando o seu saldo em bônus do aplicativo.
					<br />
					<br />
					- Após a inscrição, os participantes podem acessar as
					rodadas de perguntas durante o período do torneio.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							4. Estrutura das Perguntas e Pontuação
						</span>
					</p>
					<br />
					- Cada rodada incluirá perguntas de múltipla escolha,
					afirmação e/ou respostas de aproximação sobre jogos e
					estatísticas de equipes.
					<br />
					- Os pontos serão atribuídos com base na precisão das
					respostas: respostas corretas receberão os pontos.
					<br />
					- A premiação será realizada de acordo com a posição do
					participante, respeitando os critérios definidos para cada
					liga, a tabela que detalha o percentual e valor da
					distribuição de prêmios de cada liga está disponível na aba
					“Premiação” de cada torneio.
					<br />
					<br />
					Atenção: caso um evento associado ao torneio seja suspenso,
					cancelado ou adiado por mais de 4 horas, as perguntas
					relacionadas a ele serão canceladas e a pontuação,
					desconsiderada. Caso todas as perguntas de um torneio sejam
					canceladas, o torneio será cancelado e o valor da inscrição
					estornado para a conta do participante.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							5. Premiação
						</span>
					</p>
					<br />
					- A premiação será baseada em acertos a cada desafio do
					campeonato, sem pontuação cumulativa.
					<br />
					- Os prêmios podem incluir dinheiro, produtos ou
					vales-presente. A lista completa de prêmios será divulgada
					no início de cada torneio, junto com sua data de início e
					término, conforme o horário local de Brasília.
					<br />
					<br />
					<b>
						- Os torneios não é de forma alguma patrocinado,
						administrado, endossado ou associado à Apple Inc (App
						Store) ou à Google LLC (Google Play). Tanto a Google LLC
						(Google Play) quanto a Apple Inc (App Store). estão
						completamente isentas de qualquer responsabilidade em
						relação ao torneio, sendo a responsabilidade unicamente
						da empresa FUTPro.
					</b>
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							6. Política de Reembolso
						</span>
					</p>
					<br />
					Não haverá reembolso após a inscrição, exceto em caso de
					cancelamento do torneio pela FUTPro.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							7. Termos Gerais
						</span>
					</p>
					<br />
					- A FUTPro reserva-se o direito de modificar ou cancelar o
					torneio em circunstâncias excepcionais.
					<br />
					- Qualquer violação das regras resultará na desclassificação
					do participante.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							8. Contato
						</span>
					</p>
					<br />
					Para dúvidas ou suporte, entre em contato através do nosso
					Canal de{' '}
					<span
						onClick={() => {
							navigate('/help');
						}}
						className="cursor-pointer font-bold"
					>
						Ajuda e Suporte
					</span>
					<br />
					<br />
				</div>
			</div>

			<Footer />
		</Layout>
	);
};
