export const Max = ({ color }: any) => {
	return (
		<svg
			width="89"
			height="61"
			viewBox="0 0 89 61"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="h-5 w-6"
		>
			<mask id="path-1-inside-1_30_233" fill="white">
				<rect width="89" height="61" rx="3.4" />
			</mask>
			<rect
				width="89"
				height="61"
				rx="3.4"
				stroke={color}
				stroke-width="10"
				mask="url(#path-1-inside-1_30_233)"
			/>
			<path
				d="M13.3927 42V21.99H18.3427L25.2127 31.56L22.5727 31.65L29.6827 21.99H34.5127V42H29.6527V25.65L30.6127 27.21L23.7727 36.3L16.9927 27.06L18.0427 26.34V42H13.3927ZM37.0238 42L44.5838 21.99H49.8938L56.9738 42H51.9938L45.6038 23.13L47.9738 22.92L41.6138 42H37.0238ZM41.2838 37.68L42.2738 34.11H51.3638L51.8138 37.68H41.2838ZM77.416 42H71.386L57.736 21.99H63.766L77.416 42ZM67.486 29.4L71.836 21.99H77.206L70.006 33L67.486 29.4ZM68.296 33.63L63.226 42H57.976L65.746 30.3L68.296 33.63Z"
				fill={color}
			/>
		</svg>
	);
};
