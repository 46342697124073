import { useEffect, useState } from 'react';

export const KeepAlive = ({ children }: any) => {
	const [cachedElement, setCachedElement] = useState(null);

	useEffect(() => {
		if (!cachedElement) {
			setCachedElement(children);
		}
	}, [children, cachedElement]);

	return <>{cachedElement}</>;
};
