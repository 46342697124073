import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTokenGetStreamStore, useTokenStore } from '../../../../store/token';
import {
	CurrencyFormatter,
	sentryCapture,
	showWalletAndBalance,
} from '../../../../helpers/utils';
import { SentryError } from '../../../../types/enums';
import { useApi } from '../../../../hooks/useApi';
import { TournamentData } from '../../../../types/types';
import Tags from '../../../../components/ui/tags';
import CarouselTournament from '../../../../components/Carousel/Tournament';
import Button from '../../../../components/ui/button';
import { TableEnrolls } from '../../../../components/TableEnrolls';
import CheckboxCustomIcon from '../../../../components/ui/CheckBoxCustom';
import { RiDownload2Line, RiFileCopyLine } from '@remixicon/react';
import { ProvablyFairIcon } from '../../../../components/Icons/ProvablyFair';
import { copyToClipboard } from '../../../../helpers/utilsUi';
import { LiveChat } from '../../../../components/LiveChat';
import { FutProIcon } from '../../../../components/Icons/FutProIcon';
import { useNavigateStore } from '../../../../store/navigation';
import { StopWatch } from '../../../../components/Stopwatch';

interface iProps {
	tabsSelect: string;
	filter: any;
	onChangeFilter: (type: string, status: string) => void;
}

export const MyTournament = ({
	tabsSelect,
	filter,
	onChangeFilter,
}: iProps) => {
	const api = useApi();
	const navigate = useNavigate();
	const token = useTokenStore((state: any) => state.token);
	const tokenGetStream = useTokenGetStreamStore(
		(state) => state.tokenGetStream
	);
	const { saveNavigate, resetNavigate, goBackButton } = useNavigateStore();
	const navigateState = useNavigateStore((state: any) => state.navigateState);
	const cameFromBackButton = useNavigateStore(
		(state: any) => state.cameFromBackButton
	);

	const pageSize = 3;
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<TournamentData[]>([]);
	const [page, setPage] = useState(0);
	const [totalPage, setTotalPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [isFirstReq, setIsFirstReq] = useState(true);

	useEffect(() => {
		if (
			cameFromBackButton &&
			navigateState &&
			navigateState.tabsSelect === 'Meus Torneios'
		) {
			setData(navigateState.data);
			setPage(navigateState.page);
			setTotalPage(navigateState.totalPage);
			setHasMore(navigateState.hasMore);
			setTimeout(() => {
				const goToElement = document.getElementById(
					navigateState.tournamentId
				);
				if (goToElement) {
					goToElement.scrollIntoView({
						behavior: 'auto',
					});
				} else {
					console.error(
						'Elemento não encontrado:',
						navigateState.tournamentId
					);
				}
			}, 0);
			goBackButton(false);
			resetNavigate();
		}
	}, []);

	useEffect(() => {
		if (!cameFromBackButton) {
			if (loading) return;
			if (tabsSelect === 'Meus Torneios') {
				fetchMyTournament(page);
			}
		}
	}, [page, filter.open, filter.finish]);

	useEffect(() => {
		if (!cameFromBackButton) {
			if (
				!loading &&
				tabsSelect === 'Meus Torneios' &&
				data.length === 0 &&
				!isFirstReq
			) {
				fetchMyTournament(page);
			}
		}
	}, [tabsSelect]);

	useEffect(() => {
		if (!cameFromBackButton) {
			if (loading) return;

			setHasMore(true);
			setData([]);
			setPage(0);
		}
	}, [filter.open, filter.finish]);

	const fetchMyTournament = async (page: number) => {
		setLoading(true);
		try {
			if (totalPage !== 0 && page >= totalPage) {
				setHasMore(false);
				return;
			}
			const { open, finish } = filter;
			const res = await api.getMyTournament(
				{ pageSize, offset: page * pageSize, open, finish },
				token
			);
			if (!res?.success) {
				throw new Error(res.message);
			}

			setTotalPage(res.totalPages);
			setData((prev: any) => [...prev, ...res.data]);
			if (res.totalPages <= 1) {
				setHasMore(false);
			} else {
				setHasMore(true);
			}
		} catch (error: any) {
			sentryCapture(error, 'getTournament', SentryError.Error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="flex flex-col gap-6 mb-6">
			<div className="flex flex-row gap-4">
				<div className="h-5 flex-row justify-start items-start inline-flex">
					<div className="flex flex-row items-center gap-2">
						<CheckboxCustomIcon
							checked={filter.open === 'Inscrições abertas'}
							onCheckedChange={() => {
								if (loading) return;
								if (filter.open === '') {
									onChangeFilter(
										'open',
										'Inscrições abertas'
									);
								} else {
									onChangeFilter('open', '');
								}
							}}
						/>
						<div
							onClick={() => {
								if (loading) return;
								if (filter.open === '') {
									onChangeFilter(
										'open',
										'Inscrições abertas'
									);
								} else {
									onChangeFilter('open', '');
								}
							}}
							className="text-custom-gray10 text-sm font-normal font-bahnschrift leading-tight cursor-pointer"
						>
							Inscrições abertas
						</div>
					</div>
				</div>
				<div className="h-5 flex-row justify-start items-start inline-flex">
					<div className="flex flex-row items-center gap-2">
						<CheckboxCustomIcon
							checked={filter.finish === 'Em Apuração'}
							onCheckedChange={() => {
								if (loading) return;
								if (filter.finish === '') {
									onChangeFilter('closed', 'Em Apuração');
								} else {
									onChangeFilter('closed', '');
								}
							}}
						/>
						<div
							onClick={() => {
								if (loading) return;
								if (filter.finish === '') {
									onChangeFilter('closed', 'Em Apuração');
								} else {
									onChangeFilter('closed', '');
								}
							}}
							className="text-custom-gray10 text-sm font-normal font-bahnschrift leading-tight cursor-pointer"
						>
							Finalizados
						</div>
					</div>
				</div>
			</div>

			{data &&
				data.map((tournament, index) => (
					<div
						key={index}
						id={tournament.id}
						className="flex flex-col gap-2 bg-custom-white1 p-2.5 rounded-lg"
					>
						<div
							onClick={(e) => {
								e.stopPropagation();
								navigate(`/tournaments/${tournament.id}`);
								saveNavigate({
									tournamentId: tournament.id,
									data,
									tabsSelect,
									page,
									totalPage,
									hasMore,
									filter,
								});
							}}
							className="w-full flex flex-col gap-2 rounded-lg py-3"
						>
							<Tags status={tournament.tournamentStatus} />
							{tournament?.tournamentStatus !== 0 ? (
								<div className="w-full rounded-lg">
									<div className="flex flex-col items-start justify-start gap-6 p-1.5 w-full">
										<div className="flex flex-col items-start justify-start gap-0.5 w-full">
											<div className="text-primary font-bold text-xs font-bahnschrift break-words w-full">
												<strong>Torneio</strong>
											</div>
											<div className="flex flex-col items-start justify-start gap-2 w-full ">
												<div className="flex flex-col items-start justify-start gap-1 w-full">
													<div className="text-black font-bold text-2xl font-bahnschrift break-words w-full">
														<strong>
															{tournament?.title}
														</strong>
													</div>
												</div>
												<div className="text-black max-[360px]:text-sm text-base font-light font-bahnschrift break-words w-full flex items-center gap-1">
													{tournament?.reference}
												</div>
											</div>
										</div>
									</div>
								</div>
							) : (
								<div className="w-full flex flex-col">
									{tournament?.content ? (
										tournament.content.image ? (
											<picture>
												<source
													srcSet={
														tournament.content.image
													}
													media="(max-width: 767px)"
													className="lg:h-80 w-full h-full object-cover z-0"
												/>
												<source
													srcSet={
														tournament.content.image
													}
													media="(max-width: 768px)"
													className="lg:h-80 w-full h-full object-cover z-0"
												/>
												<source
													srcSet={
														tournament.content
															.imageWeb
													}
													media="(max-width: 1024px)"
													className="lg:h-80 w-full h-full object-cover z-0"
												/>
												<img
													className="lg:h-80 w-full h-full object-cover z-0"
													alt={
														tournament.content.title
													}
													src={
														tournament.content
															.imageWeb
													}
												/>
											</picture>
										) : (
											<div
												className="w-full h-full z-0"
												dangerouslySetInnerHTML={{
													__html: tournament?.content
														.html,
												}}
											/>
										)
									) : (
										<div className="flex flex-col items-start justify-start px-1.5 py-3 w-full rounded-lg">
											<div className="flex flex-col items-start justify-start gap-0.5 w-full">
												<div className="text-primary font-bold text-xs font-bahnschrift break-words w-full">
													<strong>Torneio</strong>
												</div>
												<div className="flex flex-col items-start justify-start gap-2 w-full h-full">
													<div className="flex flex-col items-start justify-start gap-2 w-full">
														<div className="flex flex-col items-start justify-start gap-1 w-full">
															<strong>
																{
																	tournament?.title
																}
															</strong>
														</div>
														<div className="text-black max-[360px]:text-sm text-base font-light font-bahnschrift break-words w-full flex-1 gap-1">
															Participe e concorra
															a{' '}
															{showWalletAndBalance() ? (
																'R$ '
															) : (
																<FutProIcon
																	style="w-4 h-4"
																	color="#000000"
																/>
															)}
															{CurrencyFormatter(
																Number(
																	tournament.leaguesTournament.reduce(
																		(
																			acc,
																			league
																		) => {
																			return (
																				acc +
																				parseFloat(
																					league.prizeAmt
																				)
																			);
																		},
																		0
																	)
																)
															)}{' '}
															em prêmio
														</div>
														<div className="text-black text-xs font-light font-bahnschrift leading-[1.2] break-words w-full">
															Mostre que sabe tudo
															sobre esportes
															respondendo até{' '}
															{
																tournament?.optionSelection
															}{' '}
															perguntas do quiz
															deste torneio.
														</div>
														<div className="text-black text-xs font-light font-bahnschrift leading-[1.2] break-words w-full">
															Entre as{' '}
															{
																tournament?.optionTotal
															}{' '}
															perguntas
															disponíveis,
															responda até{' '}
															{
																tournament?.optionSelection
															}
															, mostrando a sua
															habilidade para
															conquistar o máximo
															de pontos e garantir
															o seu prêmio.
														</div>
													</div>
												</div>
											</div>
										</div>
									)}

									<div className="justify-between w-full items-center inline-flex cursor-pointer gap-1 font-bahnschrift text-xs text-[#a2a5ab]">
										<StopWatch data={tournament} />
									</div>
								</div>
							)}
						</div>

						{tournament.tournamentStatus === 0 && (
							<CarouselTournament
								data={tournament?.leaguesTournament.sort(
									(a: any, b: any) => {
										return a.sequence - b.sequence;
									}
								)}
								tournamentId={tournament.id}
								tournamentStatus={tournament.tournamentStatus}
								enrollPage={false}
								navigation={{
									tournamentId: tournament.id,
									data,
									tabsSelect,
									page,
									totalPage,
									hasMore,
									filter,
								}}
							/>
						)}
						<TableEnrolls
							thead={[
								{
									title:
										tournament.participants.filter(
											(enroll: any) => enroll.reqIsPlayer
										).length === 1
											? 'Minha Inscrição'
											: 'Minhas Inscrições',
								},
								{
									title: 'Liga',
								},
								{
									title: 'Posição',
								},
								{
									title: 'Pontos',
								},
								{
									title: 'Prêmio',
								},
							]}
							tbody={tournament.participants.filter(
								(enroll: any) => enroll.reqIsPlayer
							)}
							navigation={{
								tournamentId: tournament.id,
								data,
								tabsSelect,
								page,
								totalPage,
								hasMore,
								filter,
							}}
						/>
					</div>
				))}

			{hasMore && (
				<Button
					isLoading={loading}
					onClick={() => {
						setPage(page + 1);
					}}
					className="w-full px-3.5 py-3.5"
				>
					Carregar mais
				</Button>
			)}
		</div>
	);
};
