import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';

const AppUrlListener: React.FC = () => {
	const navigate = useNavigate();

	useEffect(() => {
		let listenerHandle: PluginListenerHandle | undefined;

		const setupListener = async () => {
			listenerHandle = await App.addListener(
				'appUrlOpen',
				(event: URLOpenListenerEvent) => {
					const domain = '.com.br';
					const pathArray = event.url.split(domain);
					const appPath = pathArray.pop();

					if (appPath) {
						navigate(appPath);
					}
				}
			);
		};
		setupListener();

		return () => {
			listenerHandle?.remove();
		};
	}, []);

	return null;
};

export default AppUrlListener;
