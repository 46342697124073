import { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { toast } from 'sonner';
import Toast from '../ui/toast';
import { Clipboard } from '@capacitor/clipboard';
import Button from '../ui/button';
import Modal from '../Modal/modal';
import { socket, useApi } from '../../hooks/useApi';
import { FinanceStatusType, SentryError } from '../../types/enums';
import { useModalBalanceAlertStore } from '../../store/modalBalanceAlert';
import { useNavigate } from 'react-router-dom';
import { CurrencyFormatter, sentryCapture } from '../../helpers/utils';
import { App } from '@capacitor/app';
import { useAuthContext } from '../../contexts/Auth/AuthContext';
import { useTokenStore } from '../../store/token';

interface PixDetailsProps {
	data: any;
	onClosePayment: any;
}

export const PixInfoDetails = ({ data, onClosePayment }: PixDetailsProps) => {
	const navigate = useNavigate();
	const { urlAction } = useModalBalanceAlertStore();
	const token = useTokenStore((state: any) => state.token);
	const api = useApi();
	const { updateProfile } = useAuthContext();
	const { txid } = data;
	const [openModal, setOpenModal] = useState(false);
	const [openSuccessModal, setOpenSuccessModal] = useState(false);

	useEffect(() => {
		const verifyAppState = () => {
			if (document.visibilityState === 'visible') {
				verifyPixId(txid);
			}
		};

		if (Capacitor.isNativePlatform()) {
			const setupListener = async () => {
				const handle = await App.addListener(
					'appStateChange',
					(appState) => {
						if (appState.isActive) {
							verifyPixId(txid);
						}
					}
				);
				return handle;
			};

			const listenerPromise = setupListener();
			return () => {
				listenerPromise.then((handle) => handle.remove());
			};
		} else {
			document.addEventListener('visibilitychange', verifyAppState);

			return () => {
				document.removeEventListener(
					'visibilitychange',
					verifyAppState
				);
			};
		}
	}, [App, txid]);

	useEffect(() => {
		setOpenModal(!openModal);
		socket.on('connect', () => {});
		socket.on('disconnect', () => {});

		if (txid) {
			socket.connect();
			socket.on('paymentConfirmation', (data) => {
				const { transactionId, status } = data;
				if (
					txid === transactionId &&
					status === FinanceStatusType.EXECUTED
				) {
					setOpenModal(false);
					setOpenSuccessModal(true);
					socket.disconnect();
					if (urlAction !== '') {
						setTimeout(() => {
							navigate(urlAction);
						}, 2000);
					}
				}
			});
		}
	}, [txid]);

	const verifyPixId = async (txid: string) => {
		try {
			const response = await api.verifyPixPerTxId(token, txid);
			if (response?.success) {
				if (Capacitor.isNativePlatform()) {
					await updateProfile();
					setOpenModal(false);
					setOpenSuccessModal(true);
					socket.disconnect();
				} else {
					await updateProfile();
					setOpenModal(false);
					setOpenSuccessModal(true);
					socket.disconnect();
				}
			}
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'verifyPixId', SentryError.Error);
		}
	};

	const copyToClipboard = async () => {
		try {
			await Clipboard.write({ string: data.copyPaste });
			toast(<Toast variant="success">Código copiado com sucesso!</Toast>);
		} catch (err) {
			console.log('Falha ao copiar o texto', err);
			toast(<Toast variant="error">Falha ao copiar o código.</Toast>);
		}
	};

	const modalSuccess = () => {
		return (
			<Modal
				id="depositSucess"
				open={openSuccessModal}
				onClose={() => {
					setOpenSuccessModal(!openSuccessModal);
					onClosePayment();
				}}
			>
				<div className="flex flex-col gap-6 items-center">
					<p className="text-2xl font-bold text-left text-custom-gray2 font-bahnschrift">
						Depósito Pix
					</p>
					<p className="w-80 text-base text-center text-custom-gray2 font-bahnschrift">
						Depósito Pix de R${' '}
						{CurrencyFormatter(Number(data?.deposit))} recebido e
						creditado na sua conta
					</p>

					<Button
						onClick={() => {
							setOpenSuccessModal(!openSuccessModal);
							onClosePayment();
						}}
						className="w-full py-2 px-3.5"
					>
						Fechar
					</Button>
				</div>
			</Modal>
		);
	};

	if (Capacitor.isNativePlatform()) {
		return (
			<>
				<p className="text-xl text-center font-semibold text-custom-gray1 pb-5 font-bahnschrift">
					Depositar
				</p>

				<div className="flex flex-col pt-5 gap-5">
					<p className="text-base text-center text-custom-gray3 font-bahnschrift">
						<span className="text-base text-center text-custom-gray3 font-bahnschrift">
							Depósito Pix
						</span>
						<br />
						<span className="text-base font-semibold text-center text-custom-gray3 font-bahnschrift">
							R$ {CurrencyFormatter(Number(data?.deposit))}
						</span>
					</p>

					<div className="flex flex-col items-center gap-[30px]">
						<img
							src={data.qrCode}
							className="w-[190px] h-[190px] object-container"
							alt="qrCodePix"
						/>
						<p className="w-80 text-xs text-center text-custom-gray2 font-bahnschrift">
							Conclua a operação no App do seu banco. Assim que
							recebermos a confirmação do seu pagamento
							creditaremos R${' '}
							{CurrencyFormatter(Number(data?.deposit))} na sua
							carteira e te avisaremos
						</p>
						<Button
							onClick={copyToClipboard}
							className="w-full px-4 py-4"
						>
							Clique para pix copia e cola
						</Button>
					</div>
				</div>
				{modalSuccess()}
			</>
		);
	}

	return (
		<>
			<Modal
				id="deposit"
				open={openModal}
				onClose={() => {
					setOpenModal(!openModal);
					onClosePayment();
				}}
			>
				<p className="text-xl text-center font-semibold text-custom-gray1 pb-5 font-bahnschrift">
					Depositar
				</p>

				<div className="flex flex-col pt-5 gap-5">
					<p className="text-base text-center text-custom-gray3 font-bahnschrift">
						<span className="text-base text-center text-custom-gray3 font-bahnschrift">
							Depósito Pix
						</span>
						<br />
						<span className="text-base font-semibold text-center text-custom-gray3 font-bahnschrift">
							R$ {CurrencyFormatter(Number(data?.deposit))}
						</span>
					</p>

					<div className="flex flex-col items-center gap-[30px]">
						<img
							src={data.qrCode}
							className="w-[190px] h-[190px] object-container"
							alt="qrCodePix"
						/>
						<p className="w-80 text-xs text-center text-custom-gray2 font-bahnschrift">
							Conclua a operação no App do seu banco. Assim que
							recebermos a confirmação do seu pagamento
							creditaremos R${' '}
							{CurrencyFormatter(Number(data?.deposit))} na sua
							carteira e te avisaremos
						</p>
						<Button
							onClick={copyToClipboard}
							className="w-full px-4 py-4"
						>
							Clique para pix copia e cola
						</Button>
					</div>
				</div>
			</Modal>
			{modalSuccess()}
		</>
	);
};
