export const Min = ({ color }: any) => {
	return (
		<svg
			width="89"
			height="61"
			viewBox="0 0 89 61"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="h-5 w-6"
		>
			<mask id="path-1-inside-1_30_232" fill="white">
				<rect width="89" height="61" rx="3.4" />
			</mask>
			<rect
				width="89"
				height="61"
				rx="3.4"
				stroke={color}
				stroke-width="10"
				mask="url(#path-1-inside-1_30_232)"
			/>
			<path
				d="M16.8033 42.5V21.156H22.0833L29.4113 31.364L26.5953 31.46L34.1793 21.156H39.3313V42.5H34.1473V25.06L35.1713 26.724L27.8753 36.42L20.6433 26.564L21.7633 25.796V42.5H16.8033ZM43.8658 42.5V21.156H49.0498V42.5H43.8658ZM53.5845 42.5V21.156H58.0005L68.9765 35.94L67.4405 39.14V21.156H72.2085V42.5H68.3045L57.1365 27.908L58.3525 25.572V42.5H53.5845Z"
				fill={color}
			/>
		</svg>
	);
};
