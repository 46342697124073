import * as LiveUpdates from '@capacitor/live-updates';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';
import OneSignal from 'onesignal-cordova-plugin';
import OneSignalWeb from 'react-onesignal';
import { Filesystem } from '@capacitor/filesystem';
import { Camera } from '@capacitor/camera';

export const initializeOneSignal = async (oneSignalKey: string) => {
	if (Capacitor.isNativePlatform()) {
		OneSignal.Debug.setLogLevel(6);
		OneSignal.initialize(oneSignalKey);
		OneSignal.Notifications.addEventListener('click', async (e) => {
			let clickData = await e.notification;
		});

		const permission = await OneSignal.Notifications.getPermissionAsync();
		if (!permission) {
			try {
				await OneSignal.Notifications.requestPermission(true);
			} catch (err) {
				console.error('Erro ao solicitar permissão: ', err);
			}
		}
	} else {
		await OneSignalWeb.Debug.setLogLevel('6');
		await OneSignalWeb.init({ appId: oneSignalKey });
	}
};

export const initializePermission = async () => {
	if (Capacitor.isNativePlatform()) {
		const permissions = await Filesystem.checkPermissions();
		if (permissions.publicStorage !== 'granted') {
			await Filesystem.requestPermissions();
		}

		const permissionCamera = await Camera.checkPermissions();
		if (permissionCamera.camera !== 'granted') {
			await Camera.requestPermissions();
		}
	}
};

export const initializeLiveUpdates = async () => {
	if (Capacitor.isNativePlatform()) {
		try {
			const result = await LiveUpdates.sync();

			console.log('Resultado da sincronização:', result);

			if (result.activeApplicationPathChanged) {
				await LiveUpdates.reload();
			} else {
				console.log('Aplicativo já está atualizado.');
			}
		} catch (error) {
			console.error(
				'Erro durante a sincronização de Live Updates:',
				error
			);
		} finally {
			// Oculta a splash screen após a sincronização
			await SplashScreen.hide();
		}
	}
};
