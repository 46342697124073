import { useParams } from 'react-router-dom';
import { Layout } from '../../../components/layout';
import { useTokenGetStreamStore, useTokenStore } from '../../../store/token';
import { useCallback, useEffect, useState } from 'react';
import {
	areMandatoryQuestionsAnswered,
	CurrencyFormatter,
	sentryCapture,
	showWalletAndBalance,
} from '../../../helpers/utils';
import { SentryError } from '../../../types/enums';
import { useApi } from '../../../hooks/useApi';
import { TournamentData } from '../../../types/types';
import {
	RiDownload2Line,
	RiFileCopyLine,
	RiRestartLine,
} from '@remixicon/react';
import TabsInLine from '../../../components/TabsInLine';
import Carousel from '../../../components/Carousel';
import { useEnrollContext } from '../../../contexts/Enroll/EnrollContext';
import ButtonBack from '../../../components/ui/buttonBack';
import { Options } from './Option';
import { toast } from 'sonner';
import Toast from '../../../components/ui/toast';
import { useModalBalanceAlertStore } from '../../../store/modalBalanceAlert';
import { useAuthContext } from '../../../contexts/Auth/AuthContext';
import { LiveChat } from '../../../components/LiveChat';
import { ResultsOptions } from '../Tournaments/ResultsOptions';
import { Games } from '../../../components/Tournaments/Games';
import { FutProIcon } from '../../../components/Icons/FutProIcon';
import { Capacitor } from '@capacitor/core';
import { ProvablyFairIcon } from '../../../components/Icons/ProvablyFair';
import { copyToClipboard } from '../../../helpers/utilsUi';
import { Participants } from './Participants';
import { Rule } from './Rules';
import { StopWatch } from '../../../components/Stopwatch';
import { Awards } from './Awards';

export const TournamentById = () => {
	const { id } = useParams();
	const api = useApi();
	const { user } = useAuthContext();
	const {
		enroll,
		enrollOptions,
		optionTournament,
		optionSelection,
		initialSelectEnroll,
		enrollOption,
		SetOptionTournament,
		SetOptionSelection,
		SetLeague,
		SetTournament,
		openModalEnroll,
		depositReturnEnroll,
	} = useEnrollContext();
	const {
		urlAction,
		leagues,
		optionsSelect: optionSelected,
		clearEnrollsInfoModal,
	} = useModalBalanceAlertStore();
	const tokenGetStream = useTokenGetStreamStore(
		(state) => state.tokenGetStream
	);
	const token = useTokenStore((state: any) => state.token);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<TournamentData | null>(null);
	const [optionsSelect, setOptionsSelect] = useState<any>([]);
	const [countSelect, setCountSelect] = useState(0);
	const [openTournamentId, setOpenTournamentId] = useState(false);
	const prizeAmtTournament =
		data &&
		data.leaguesTournament.reduce((acc, league) => {
			return acc + parseFloat(league.prizeAmt);
		}, 0);
	useEffect(() => {
		fetchTournament();
	}, []);

	useEffect(() => {
		enrollOption(optionsSelect);
		setCountSelect(optionsSelect.length);
	}, [optionsSelect]);

	useEffect(() => {
		if (!data) return;
		if (
			!areMandatoryQuestionsAnswered(optionTournament, enrollOptions) &&
			countSelect === optionSelection
		) {
			const handler = setTimeout(() => {
				toast(
					<Toast variant="error">
						Responda as perguntas obrigatórias.
					</Toast>
				);
			}, 1000);
			return () => clearTimeout(handler);
		} else {
			if (enroll.length > 0 && countSelect === optionSelection) {
				if (!user) {
					return;
				}
				setTimeout(() => {
					openModalEnroll();
				}, 2000);
			}
		}
	}, [enroll, enrollOptions, user]);

	const fetchTournament = async () => {
		if (!id) return;
		try {
			setLoading(true);
			const res = await api.getTournament(id, token);

			if (!res?.success) {
				throw new Error(res.message);
			}
			setData(res.data);
			SetTournament({
				tournamentId: id,
				tournamentName: res.data.title,
				tournamentStatus: res.data.tournamentStatus,
				sharedUrl:
					Capacitor.getPlatform() === 'ios'
						? res.data.sharedUrlIOS
						: Capacitor.getPlatform() === 'android'
						? res.data.sharedUrlAndroid
						: res.data.sharedUrl,
			});
			SetLeague(res.data.leaguesTournament);
			SetOptionTournament(res.data.optionsTournament);
			SetOptionSelection(parseInt(res.data.optionSelection));

			if (urlAction) {
				setOptionsSelect(optionSelected);
				enrollOption(optionSelected);
				depositReturnEnroll(leagues);
				clearEnrollsInfoModal();
				openModalEnroll();
			} else {
				initialSelectEnroll({
					leagueId: res.data.leaguesTournament[0].league.id,
					leagueName: res.data.leaguesTournament[0].league.title,
					entryAmt: res.data.leaguesTournament[0].entryAmt,
					prizeAmt: res.data.leaguesTournament[0].prizeAmt,
				});
			}
		} catch (error: any) {
			sentryCapture(error, 'getTournament', SentryError.Error);
		} finally {
			setLoading(false);
		}
	};

	const handleSelectOption = useCallback((newOption: any) => {
		setOptionsSelect((prevOptions: any) => {
			const existingOptionIndex = prevOptions.findIndex(
				(option: any) => option.optionId === newOption.optionId
			);
			if (existingOptionIndex !== -1) {
				if (newOption.delete) {
					return prevOptions.filter(
						(option: any) => option.optionId !== newOption.optionId
					);
				} else {
					const updatedOptions = [...prevOptions];
					updatedOptions[existingOptionIndex] = newOption;
					return updatedOptions;
				}
			} else {
				return [...prevOptions, newOption];
			}
		});
	}, []);

	return (
		<Layout>
			<div className="px-3 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between py-2 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
					<ButtonBack classNameIcon="" />
					<p className="text-xl font-semibold text-custom-gray1 font-bahnschrift"></p>
					<div></div>
				</div>
				{loading ? (
					<div className="flex items-center justify-center">
						<RiRestartLine className="mr-2 h-4 w-4 animate-spin" />
					</div>
				) : (
					<div className="flex flex-col gap-2">
						<div className="w-full flex flex-col gap-2 rounded-lg py-3">
							{data?.tournamentStatus !== 0 ? (
								<div className="w-full bg-custom-gray rounded-lg">
									<div className="flex flex-col items-start justify-start gap-6 p-4 w-full ">
										<div className="flex flex-col items-start justify-start gap-0.5 w-full">
											<div className="text-primary font-bold text-xs font-bahnschrift break-words w-full">
												<strong>Torneio</strong>
											</div>
											<div className="flex flex-col items-start justify-start gap-2 w-full ">
												<div className="flex flex-col items-start justify-start gap-1 w-full">
													<div className="text-black font-bold text-2xl font-bahnschrift break-words w-full">
														<strong>
															{data?.title}
														</strong>
													</div>
												</div>
												<div className="text-black max-[360px]:text-sm text-base font-light font-bahnschrift break-words w-full flex items-center gap-1">
													{data?.reference}
												</div>
											</div>
										</div>
									</div>
									<div className="justify-end w-full items-center inline-flex px-3 cursor-pointer gap-1 font-bahnschrift text-xs text-[#a2a5ab]">
										<div
											onClick={(e) => {
												e.stopPropagation();
												setOpenTournamentId(
													!openTournamentId
												);
											}}
											className="h-8 rounded-[68px] justify-center items-center float-right inline-flex relative cursor-pointer gap-1 font-bahnschrift text-xs text-[#a2a5ab]"
										>
											<ProvablyFairIcon />
											Provably Fair
										</div>
									</div>
								</div>
							) : (
								<div className="w-full h-60">
									{data?.content ? (
										data?.content.image ? (
											<picture>
												<source
													srcSet={data.content.image}
													media="(max-width: 767px)"
													className="lg:h-80 w-full h-full object-cover z-0"
												/>
												<source
													srcSet={data.content.image}
													media="(max-width: 768px)"
													className="lg:h-80 w-full h-full object-cover z-0"
												/>
												<source
													srcSet={
														data.content.imageWeb
													}
													media="(max-width: 1024px)"
													className="lg:h-80 w-full h-full object-cover z-0"
												/>
												<img
													className="lg:h-80 w-full h-full object-cover z-0"
													alt={data.content.title}
													src={data.content.imageWeb}
												/>
											</picture>
										) : (
											<div
												className="w-full h-full z-0"
												dangerouslySetInnerHTML={{
													__html: data?.content.html,
												}}
											/>
										)
									) : (
										<div className="flex flex-col items-start justify-start gap-6 p-4 w-full h-full bg-custom-gray rounded-lg">
											<div className="flex flex-col items-start justify-start gap-0.5 w-full h-28">
												<div className="text-primary font-bold text-xs font-bahnschrift break-words w-full">
													<strong>Torneio</strong>
												</div>
												<div className="flex flex-col items-start justify-start gap-2 w-full h-full">
													<div className="flex flex-col items-start justify-start gap-1 w-full h-10">
														<div className="text-black font-bold text-2xl font-bahnschrift break-words w-full">
															<strong>
																{data?.title}
															</strong>
														</div>

														<div className="text-black max-[360px]:text-sm text-base font-light font-bahnschrift break-words w-full flex items-center gap-1">
															Participe e concorra
															a{' '}
															{showWalletAndBalance() ? (
																'R$ '
															) : (
																<FutProIcon
																	style="w-4 h-4"
																	color="#000000"
																/>
															)}
															{CurrencyFormatter(
																Number(
																	prizeAmtTournament
																)
															)}{' '}
															em prêmio
														</div>
														<div className="text-black text-xs font-light font-bahnschrift leading-[1.2] break-words w-full">
															Mostre que sabe tudo
															sobre esportes
															respondendo até{' '}
															{
																data?.optionSelection
															}{' '}
															perguntas do quiz
															deste torneio.
														</div>
														<div className="text-black text-xs font-light font-bahnschrift leading-[1.2] break-words w-full">
															Entre as{' '}
															{data?.optionTotal}{' '}
															perguntas
															disponíveis,
															responda até{' '}
															{
																data?.optionSelection
															}
															, mostrando a sua
															habilidade para
															conquistar o máximo
															de pontos e garantir
															o seu prêmio.
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
									<div className="justify-between w-full items-center inline-flex relative bottom-9 px-3 cursor-pointer gap-1 font-bahnschrift text-xs text-[#a2a5ab]">
										{data?.tournamentStatus !== 0 ? (
											<div></div>
										) : (
											<StopWatch data={data} />
										)}

										<div
											onClick={(e) => {
												e.stopPropagation();
												setOpenTournamentId(
													!openTournamentId
												);
											}}
											className="h-8 rounded-[68px] justify-center items-center float-right inline-flex relative cursor-pointer gap-1 font-bahnschrift text-xs text-[#a2a5ab]"
										>
											<ProvablyFairIcon />
											Provably Fair
										</div>
									</div>
								</div>
							)}
						</div>
						{openTournamentId && (
							<div className="px-4 py-2 bg-gray-100 rounded-lg flex-col justify-center items-start gap-2.5 inline-flex">
								<div className="self-stretch justify-start items-center gap-2 inline-flex">
									<ProvablyFairIcon />
									<div className="text-[#a2a5ab] text-[11px] font-semibold font-bahnschrift">
										Provably Fair
									</div>
								</div>

								<div className="self-stretch justify-start items-center gap-2 inline-flex">
									<div className="w-full">
										<span className="text-neutral-800 text-[13px] font-normal font-bahnschrift leading-snug">
											Chave pública gerada pelo servidor:
											<br />
										</span>
										<div className="flex flex-row gap-1 items-center justify-center">
											<input
												type="text"
												className="text-neutral-800 text-[13px] rounded font-semibold font-bahnschrift w-full p-1 border border-b-neutral-800"
												value={data?.pfPublicKey}
												disabled={true}
											/>
											<div
												onClick={() => {
													copyToClipboard(
														data?.pfPublicKey
													);
												}}
											>
												<RiFileCopyLine className="text-neutral-800" />
											</div>
										</div>
									</div>
								</div>
								{data?.pfPrivateKey && (
									<div className="self-stretch justify-start items-center gap-2 inline-flex">
										<div className="w-full">
											<span className="text-neutral-800 text-[13px] font-normal font-bahnschrift leading-snug">
												Chave privada gerada no
												servidor:
												<br />
											</span>
											<div className="flex flex-row gap-1 items-center justify-center">
												<input
													type="text"
													className="text-neutral-800 text-[13px] rounded font-semibold font-bahnschrift w-full p-1 border border-b-neutral-800"
													value={data?.pfPrivateKey}
													disabled={true}
												/>
												<div
													onClick={() => {
														copyToClipboard(
															data?.pfPrivateKey
														);
													}}
												>
													<RiFileCopyLine className="text-neutral-800" />
												</div>
											</div>
										</div>
									</div>
								)}
								{data?.pfFileUrl && (
									<div
										onClick={async () => {
											const link =
												document.createElement('a');
											link.href = data?.pfFileUrl;
											link.setAttribute(
												'download',
												'provablyFair.txt'
											);
											link.click();
										}}
										className="w-full h-8 flex justify-end"
									>
										<RiDownload2Line className="w-6 h-6 text-black" />
									</div>
								)}
							</div>
						)}
						<Carousel
							data={data?.leaguesTournament.sort(
								(a: any, b: any) => {
									return a.sequence - b.sequence;
								}
							)}
							tournamentStatus={data?.tournamentStatus}
						/>
						{id &&
							data?.tournamentStatus !== 3 &&
							data?.tournamentStatus !== 2 && (
								<LiveChat
									id={id}
									tournamentId={id}
									token={tokenGetStream}
								/>
							)}
						{data && (
							<TabsInLine
								initialPage="Jogos"
								render={[
									{
										title: 'Jogos',
										renderPage: () => (
											<Games
												eventsTournament={
													data.eventsTournament
												}
											/>
										),
									},
									{
										title:
											data.tournamentStatus === 0
												? 'Perguntas'
												: 'Resultados',
										renderPage: () =>
											data.tournamentStatus === 0 ? (
												<Options
													data={data}
													countSelect={countSelect}
													optionsSelect={
														optionsSelect
													}
													handleSelectOption={
														handleSelectOption
													}
												/>
											) : (
												<ResultsOptions data={data} />
											),
									},
									{
										title: 'Inscrições',
										renderPage: () => (
											<Participants
												tournamentStatus={
													data?.tournamentStatus
												}
												myEnrolls={data.myEnrolls}
												leagues={data?.leaguesTournament.sort(
													(a: any, b: any) => {
														return (
															a.sequence -
															b.sequence
														);
													}
												)}
												tournamentId={id}
											/>
										),
									},
									{
										title: 'Premiação',
										renderPage: () => (
											<Awards
												leaguesTournament={data?.leaguesTournament.sort(
													(a: any, b: any) => {
														return (
															a.sequence -
															b.sequence
														);
													}
												)}
												tournamentStatus={
													data?.tournamentStatus
												}
											/>
										),
									},
									{
										title: 'Regulamento',
										renderPage: () => (
											<Rule data={data.rule} />
										),
									},
								]}
							/>
						)}
					</div>
				)}
			</div>
		</Layout>
	);
};
