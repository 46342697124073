import { Capacitor } from '@capacitor/core';
import { Rule as iRule, LeaguesTournament } from '../../../../types/types';
import { TableAward } from '../../../../components/TableAward';
import TabsInTournament from '../../../../components/TabsInTournament';

interface iProps {
	leaguesTournament: LeaguesTournament[];
	tournamentStatus: number;
}

export const Awards = ({ leaguesTournament, tournamentStatus }: iProps) => {
	const awardArray: any[] = leaguesTournament
		?.filter((i) => i.award)
		.map((item: LeaguesTournament, index: number) => ({
			title: item.league.title,
			renderPage: () => (
				<TableAward
					key={index}
					award={item.award}
					tournamentStatus={tournamentStatus}
					prizeTotal={item.prizeAmt}
					registeredEnrolls={item.registeredEnrolls}
				/>
			),
		}));

	return (
		<div className="z-0 bg-[#f4f4f4] p-2 rounded-lg">
			{awardArray.length > 0 && (
				<div className="mb-4">
					<TabsInTournament
						initialPage={awardArray[0].title}
						render={awardArray}
					/>
				</div>
			)}
		</div>
	);
};
