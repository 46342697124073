import { create } from 'zustand';

interface ScrollControlState {
	scrollEvent: number;
	setScrollEvent: (scrollTop: number) => void;
}

export const useScrollControllStore = create<ScrollControlState>((set) => ({
	scrollEvent: 0,
	setScrollEvent: (scrollTop: number) => set({ scrollEvent: scrollTop }),
}));
