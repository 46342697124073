import { useState, memo, useRef, forwardRef, useEffect } from 'react';
import * as Slider from '@radix-ui/react-slider';
import { RiAddLine, RiCloseLine, RiSubtractLine } from '@remixicon/react';
import { toast } from 'sonner';
import Button from '../../ui/button';
import { Unchecked } from '../../Icons/Unchecked';
import { Checked } from '../../Icons/Checked';
import { PlayersPositionDisplay } from '../../../types/enums';
import PlayersSelect from '../../Modal/PlayersSelect';
import Toast from '../../ui/toast';
import {
	areMandatoryQuestionsAnswered,
	transformInitial,
} from '../../../helpers/utils';
import { useEnrollContext } from '../../../contexts/Enroll/EnrollContext';
import { number } from 'zod';

interface OptionItemProps {
	item: any;
	index: number;
	playersTeams: any;
	events: any[];
	optionsSelect: any;
	blockSelection: boolean;
	onChange: (id: any) => void;
}

const OptionItem = memo(
	forwardRef<HTMLDivElement, OptionItemProps>(
		(
			{
				item,
				index,
				playersTeams,
				events,
				optionsSelect,
				blockSelection,
				onChange,
			},
			ref
		) => {
			const { enrollOptions, optionTournament } = useEnrollContext();
			const toastTimeoutRef = useRef<NodeJS.Timeout | null>(null);
			const [open, setClose] = useState(false);
			const [statementReply, setStatementReply] = useState<string>('');
			const [numberReply, setNumberReply] = useState<number>(0);
			const [numberReplySelect, setNumberReplySelect] = useState<any>(
				() => {
					return events.map((event) => ({
						...event,
						teamHome: {
							...event.teamHome,
							scoreReply: 0,
						},
						teamAway: {
							...event.teamAway,
							scoreReply: 0,
						},
					}));
				}
			);
			const [numberReplySlider, setNumberReplySlider] = useState<any>(0);
			const replyNumber = numberReplySelect.reduce(
				(total: any, event: any) => {
					total += event.teamHome.scoreReply;
					total += event.teamAway.scoreReply;
					return total;
				},
				0
			);
			const [draftReply, setDraftReply] = useState<any>([]);
			const [draftReplySelect, setDraftReplySelect] = useState<any>([]);
			const positionsToFilter = item.draftPositions
				? item?.draftPositions.map(
						(position: any) => PlayersPositionDisplay[position]
				  )
				: [];

			const groupedByTeam = playersTeams
				.filter(
					(playerTeam: any) =>
						!draftReplySelect.includes(playerTeam.player.id)
				)
				.filter((playerTeam: any) =>
					positionsToFilter.includes(playerTeam.position)
				)
				.reduce((acc: any, playerTeam: any) => {
					const teamId = playerTeam.team.id;
					if (!acc[teamId]) {
						acc[teamId] = {
							team: playerTeam.team,
							players: [],
						};
					}
					acc[teamId].players.push(playerTeam.player);
					return acc;
				}, {});

			const sortedGroupedByTeam = Object.values(groupedByTeam).sort(
				(a: any, b: any) => a.team.name.localeCompare(b.team.name)
			);

			const selectedPlayers = playersTeams
				.filter((player: any) =>
					draftReplySelect.includes(player.player.id)
				)
				.filter((player: any) =>
					positionsToFilter.includes(player.position)
				)
				.sort((a: any, b: any) =>
					a.team.name.localeCompare(b.team.name)
				);
			const isSelected = optionsSelect.some(
				(option: any) => option.optionId === item.id
			);

			const layoutFormat = () => {
				if (selectedPlayers.length === 0) {
					return 'max-h-[70vh] lg:max-h-[70vh]';
				}
				if (selectedPlayers.length === 1) {
					return 'max-h-[55vh] lg:max-h-[55vh]';
				}
				if (selectedPlayers.length === 2) {
					return 'max-h-[44vh] lg:max-h-[44vh]';
				}

				return 'max-h-[44vh] lg:max-h-[44vh]';
			};

			useEffect(() => {
				const findExists = enrollOptions.find(
					(option: any) => option.optionId === item.id
				);

				if (findExists) {
					if (findExists.optionType === 0) {
						setStatementReply(
							findExists.statementReply ? 'Yes' : ''
						);
					}
					if (findExists.optionType === 1) {
						setDraftReply(findExists.draftReply);
						setDraftReplySelect(findExists.draftReply);
					}
					if (findExists.optionType === 2) {
						setNumberReply(findExists.numberReply);
					}
				}
			}, []);

			const handleChangeNumber = () => {
				if (
					!areMandatoryQuestionsAnswered(
						optionTournament,
						enrollOptions
					) &&
					!item.mandatory
				) {
					toastTimeoutRef.current = setTimeout(() => {
						toast(
							<Toast variant="error">
								Responda primeiro as perguntas obrigatórias.
							</Toast>
						);
					}, 500);
					return;
				}

				if (blockSelection && !isSelected) {
					if (toastTimeoutRef.current) {
						clearTimeout(toastTimeoutRef.current);
					}

					toastTimeoutRef.current = setTimeout(() => {
						toast(
							<Toast variant="error">
								Desmarque uma pergunta opcional para selecionar
								uma nova pergunta.
							</Toast>
						);
					}, 1000);
					return;
				}

				const reply =
					numberReplySlider > 0
						? numberReplySlider
						: numberReplySelect.reduce((total: any, event: any) => {
								total += event.teamHome.scoreReply;
								total += event.teamAway.scoreReply;
								return total;
						  }, 0);

				if (reply === 0) {
					toastTimeoutRef.current = setTimeout(() => {
						toast(
							<Toast variant="error">
								Para finalizar responda a pergunta.
							</Toast>
						);
					}, 500);
					return;
				}

				setNumberReply(reply);
				onChange({
					optionId: item.id,
					optionType: item.optionType,
					sequence: item.sequence,
					numberReply: reply,
				});
				setClose(!open);
			};

			const handleChangeStatement = (data: string) => {
				if (
					!areMandatoryQuestionsAnswered(
						optionTournament,
						enrollOptions
					) &&
					!item.mandatory
				) {
					toastTimeoutRef.current = setTimeout(() => {
						toast(
							<Toast variant="error">
								Responda primeiro as perguntas obrigatórias.
							</Toast>
						);
					}, 500);
					return;
				}
				if (blockSelection && !isSelected) {
					if (toastTimeoutRef.current) {
						clearTimeout(toastTimeoutRef.current);
					}

					toastTimeoutRef.current = setTimeout(() => {
						toast(
							<Toast variant="error">
								Desmarque uma pergunta opcional para selecionar
								uma nova pergunta.
							</Toast>
						);
					}, 1000);
					return;
				}
				setStatementReply(data);
				onChange({
					optionId: item.id,
					statementReply: true,
					optionType: item.optionType,
					sequence: item.sequence,
				});
			};
			const handleChangeDraft = (data: any) => {
				if (
					!areMandatoryQuestionsAnswered(
						optionTournament,
						enrollOptions
					) &&
					!item.mandatory
				) {
					toastTimeoutRef.current = setTimeout(() => {
						toast(
							<Toast variant="error">
								Responda primeiro as perguntas obrigatórias.
							</Toast>
						);
					}, 500);
					return;
				}
				const exists = draftReplySelect.includes(data);
				if (exists) {
					setDraftReplySelect(
						draftReplySelect.filter((item: any) => item !== data)
					);
				} else {
					if (
						draftReplySelect.length >= Number(item?.draftPlayersQtd)
					) {
						return toast(
							<Toast variant="error">
								Você já selecionou todos os jogadores.
							</Toast>
						);
					}
					setDraftReplySelect([...draftReplySelect, data]);
				}
			};
			const removeDraft = (data: any) => {
				const exists = draftReplySelect.includes(data);
				if (exists) {
					setDraftReplySelect(
						draftReplySelect.filter((item: any) => item !== data)
					);
				}
			};
			const handleSaveDraft = () => {
				if (draftReplySelect.length < Number(item?.draftPlayersQtd)) {
					const countSelect =
						Number(item?.draftPlayersQtd) - draftReplySelect.length;

					return toast(
						<Toast variant="error">
							Selecione {countSelect}{' '}
							{countSelect > 1 ? 'jogadores' : 'jogador'}!
						</Toast>
					);
				}
				setDraftReply(draftReplySelect);
				onChange({
					optionId: item.id,
					optionType: item.optionType,
					sequence: item.sequence,
					draftReply: draftReplySelect,
				});
				setClose(!open);
			};
			const removeChangeEnroll = (id: string) => {
				if (item.optionType === 0) {
					setStatementReply('');
					onChange({
						optionId: item.id,
						delete: true,
					});
					return;
				}
				if (item.optionType === 1) {
					setDraftReply([]);
					setDraftReplySelect([]);
					onChange({
						optionId: item.id,
						delete: true,
					});
					return;
				}
				if (item.optionType === 2) {
					setNumberReply(0);
					onChange({
						optionId: item.id,
						delete: true,
					});
					return;
				}
			};
			const updateScore = (
				id: string,
				team: 'home' | 'away',
				operation: '+' | '-'
			) => {
				setNumberReplySelect((prevEvents: any) =>
					prevEvents.map((event: any) => {
						if (event.id === id) {
							const updatedEvent = { ...event };
							if (team === 'home') {
								updatedEvent.teamHome.scoreReply =
									operation === '+'
										? updatedEvent.teamHome.scoreReply + 1
										: updatedEvent.teamHome.scoreReply - 1;

								updatedEvent.teamHome.scoreReply = Math.max(
									0,
									updatedEvent.teamHome.scoreReply
								);
							} else if (team === 'away') {
								updatedEvent.teamAway.scoreReply =
									operation === '+'
										? updatedEvent.teamAway.scoreReply + 1
										: updatedEvent.teamAway.scoreReply - 1;
								updatedEvent.teamAway.scoreReply = Math.max(
									0,
									updatedEvent.teamAway.scoreReply
								);
							}

							return updatedEvent;
						}
						return event;
					})
				);
			};

			const renderOption = (
				optionType: number,
				title: boolean,
				teams?: any
			) => {
				switch (optionType) {
					case 0:
						return (
							<div className="justify-between items-center gap-2 inline-flex">
								<div className="self-stretch justify-start items-center gap-2.5 inline-flex">
									<div className="text-[#9a9a9a] text-[10px] font-semibold font-bahnschrift flex gap-1">
										{teams &&
											teams.length > 0 &&
											teams?.map(
												(team: any, index: number) => (
													<img
														key={index}
														className="w-6 h-6 object-scale-down"
														src={team.image}
														alt={team.name}
													/>
												)
											)}
									</div>
								</div>
								<div
									className={`flex gap-2 justify-start items-center px-4 py-2 rounded-[68px] transition-transform duration-300 ${
										statementReply === 'Yes'
											? 'bg-[#cccccc] scale-110'
											: 'scale-100'
									}`}
								>
									<div
										className="flex justify-start items-center gap-2"
										onClick={() =>
											handleChangeStatement('Yes')
										}
									>
										{statementReply === 'Yes' ? (
											<Checked />
										) : (
											<Unchecked />
										)}
										<div className="text-custom-black text-sm font-semibold font-bahnschrift">
											Sim
										</div>
									</div>
								</div>
							</div>
						);
					case 1:
						return (
							<div className="justify-between items-center gap-4 inline-flex">
								<div className="flex flex-col gap-2 py-1">
									{selectedPlayers.map(
										(player: any, index: number) => (
											<div
												key={index}
												className="justify-between items-center inline-flex"
											>
												<div className="h-10 justify-start items-center gap-4 flex">
													{player.player.image ? (
														<img
															className="w-10 h-10 rounded-[33.55px] object-cover"
															src={
																player.player
																	.image
															}
														/>
													) : (
														<div className="w-8 h-8 rounded-full text-white font-bahnschrift text-xs bg-primary items-center justify-center flex">
															{transformInitial(
																player.player
																	.name
															)}
														</div>
													)}

													<div className="flex-col justify-center items-start gap-1.5 inline-flex">
														<div className="justify-start items-center inline-flex">
															<div className="text-custom-black text-base font-normal font-bahnschrift leading-[14px]">
																{
																	player
																		.player
																		.name
																}
															</div>
														</div>
														<div className="justify-start items-center gap-1 inline-flex">
															<img
																className="w-4 h-4 object-scale-down"
																src={
																	player.team
																		.image
																}
															/>
															<div className="text-custom-black text-xs font-light font-bahnschrift leading-[14px]">
																{
																	player.team
																		.name
																}
															</div>
														</div>
													</div>
												</div>
											</div>
										)
									)}
								</div>
								<Button
									className="py-2 px-3 mt-3"
									variant="black"
									textCustom="text-custom-white text-xs"
									onClick={() => {
										if (blockSelection && !isSelected) {
											if (toastTimeoutRef.current) {
												clearTimeout(
													toastTimeoutRef.current
												);
											}

											toastTimeoutRef.current =
												setTimeout(() => {
													toast(
														<Toast variant="error">
															Desmarque uma
															pergunta para
															selecionar!
														</Toast>
													);
												}, 1000);

											return;
										}
										setClose(!open);
									}}
								>
									Escalar
								</Button>
								<PlayersSelect
									id={item.id}
									open={open}
									onClose={() => setClose(!open)}
								>
									<div className="flex flex-col justify-between px-6 pt-7 pb-11 lg:py-0 gap-4 max-h-[90vh]">
										{selectedPlayers.length > 0 && (
											<div className="flex flex-col gap-4 ">
												<div>
													<span className="text-[#ececec] text-lg font-normal font-bahnschrift">
														Jogadores escalados
													</span>
													<span className="text-[#6b6e74] text-xs font-light pl-1 font-bahnschrift">
														(
														{
															draftReplySelect.length
														}
														/{item.draftPlayersQtd})
													</span>
												</div>

												<div className="flex flex-col gap-3 overflow-auto max-h-[23vh]">
													{selectedPlayers.map(
														(
															player: any,
															index: number
														) => (
															<div
																key={index}
																className="h-[72px] p-4 bg-custom-gray10 rounded-[5px] shadow justify-between items-center inline-flex"
																onClick={() => {
																	handleChangeDraft(
																		player
																			.player
																			.id
																	);
																}}
															>
																<div className="h-10 justify-start items-center gap-4 flex">
																	{player
																		.player
																		.image ? (
																		<img
																			className="w-10 h-10 rounded-[33.55px] object-cover"
																			src={
																				player
																					.player
																					.image
																			}
																		/>
																	) : (
																		<div className="w-8 h-8 rounded-full text-white font-bahnschrift text-xs bg-primary items-center justify-center flex">
																			{transformInitial(
																				player
																					.player
																					.name
																			)}
																		</div>
																	)}

																	<div className="flex-col justify-center items-start gap-1.5 inline-flex">
																		<div className="justify-start items-center inline-flex">
																			<div className="text-custom-white text-base font-normal font-bahnschrift leading-[14px]">
																				{
																					player
																						.player
																						.name
																				}
																			</div>
																		</div>
																		<div className="justify-start items-center gap-1 inline-flex">
																			<img
																				className="w-4 h-4 object-scale-down"
																				src={
																					player
																						.team
																						.image
																				}
																			/>
																			<div className="text-custom-white text-xs font-light font-bahnschrift leading-[14px]">
																				{
																					player
																						.team
																						.name
																				}
																			</div>
																		</div>
																	</div>
																</div>

																{draftReplySelect.includes(
																	player
																		.player
																		.id
																) ? (
																	<Button
																		variant="delete"
																		textCustom="text-primary text-sm"
																		onClick={() =>
																			removeDraft(
																				player
																					.player
																					.id
																			)
																		}
																	>
																		Dispensar
																	</Button>
																) : (
																	<Button className="py-1.5 px-3">
																		Escalar
																	</Button>
																)}
															</div>
														)
													)}
												</div>
											</div>
										)}

										<div className="flex flex-col gap-4 h-fit">
											<div>
												<span className="text-[#ececec] text-lg font-normal font-bahnschrift">
													Monte sua equipe{' '}
												</span>
											</div>

											<div
												className={
													'flex flex-col gap-3 overflow-auto ' +
													layoutFormat()
												}
											>
												{sortedGroupedByTeam.map(
													(
														draft: any,
														index: number
													) => (
														<div
															key={index}
															className="justify-between items-center inline-flex flex-col gap-1"
														>
															<div className="p-4 w-full bg-custom-gray10 rounded-[5px] shadow justify-between items-center inline-flex">
																<div className="justify-start items-center gap-2 flex">
																	<div className="text-custom-white text-base font-normal font-bahnschrift leading-[14px]">
																		<img
																			className="w-7 h-7 object-scale-down"
																			src={
																				draft
																					.team
																					.image
																			}
																		/>
																	</div>
																	<div className="text-custom-white text-base font-normal font-bahnschrift leading-[14px]">
																		{
																			draft
																				.team
																				.name
																		}
																	</div>
																</div>
															</div>
															<div className="flex flex-col w-full pl-8 gap-1">
																{draft.players
																	.sort(
																		(
																			a: any,
																			b: any
																		) =>
																			a.name.localeCompare(
																				b.name
																			)
																	)
																	.map(
																		(
																			player: any,
																			index: number
																		) => (
																			<div
																				key={
																					index
																				}
																				className="py-4 px-4 bg-custom-gray10 rounded-[5px] shadow justify-between items-center inline-flex"
																				onClick={() => {
																					handleChangeDraft(
																						player.id
																					);
																				}}
																			>
																				<div className="h-7 justify-start items-center gap-4 flex">
																					{player.image ? (
																						<img
																							className="w-10 h-10 rounded-[33.55px] object-cover"
																							src={
																								player.image
																							}
																						/>
																					) : (
																						<div className="w-8 h-8 rounded-full text-white font-bahnschrift text-xs bg-primary items-center justify-center flex">
																							{transformInitial(
																								player.name
																							)}
																						</div>
																					)}

																					<div className="flex-col justify-center items-start gap-1.5 inline-flex">
																						<div className="justify-start items-center inline-flex">
																							<div className="text-custom-white text-base font-normal font-bahnschrift leading-[14px]">
																								{
																									player.name
																								}
																							</div>
																						</div>
																					</div>
																				</div>

																				{draftReplySelect.includes(
																					player.id
																				) ? (
																					<Button
																						variant="delete"
																						textCustom="text-primary text-sm"
																						onClick={() =>
																							removeDraft(
																								player.id
																							)
																						}
																					>
																						Dispensar
																					</Button>
																				) : (
																					<Button className="py-1.5 px-3">
																						Escalar
																					</Button>
																				)}
																			</div>
																		)
																	)}
															</div>
														</div>
													)
												)}
											</div>
										</div>
										<div className="flex flex-row gap-4">
											<Button
												className="px-5 py-3 border border-custom-white"
												variant="black"
												textCustom="text-custom-white"
												onClick={() => {
													setClose(!open);
													const findExists =
														enrollOptions.find(
															(option: any) =>
																option.optionId ===
																item.id
														);

													if (findExists) {
														setDraftReply(
															findExists.draftReply
														);
														setDraftReplySelect(
															findExists.draftReply
														);
													} else {
														setDraftReply([]);
														setDraftReplySelect([]);
													}
												}}
											>
												Fechar
											</Button>
											<Button
												className="w-full py-3"
												textCustom="text-custom-white"
												onClick={handleSaveDraft}
											>
												Finalizar Escalação
											</Button>
										</div>
									</div>
								</PlayersSelect>
							</div>
						);
					case 2:
						return (
							<div className="grid grid-flow-row grid-cols-3">
								<div></div>
								<div className="flex flex-col gap-2 py-1 mt-3 text-custom-black text-xl w-full items-center text-center font-semibold font-bahnschrift">
									<div>
										{numberReply !== 0 ? numberReply : ''}
										<span className="text-custom-black text-base text-center w-full font-semibold font-bahnschrift">
											{numberReply !== 0 &&
											item.draftScout
												? numberReply <= 1
													? item.draftScout.name
													: item.draftScout.nameSuffix
												: ''}
										</span>
									</div>
								</div>
								<div className="flex justify-end">
									<Button
										className="py-2 px-3 mt-3"
										variant="black"
										textCustom="text-custom-white text-xs"
										onClick={() => {
											if (blockSelection && !isSelected) {
												if (toastTimeoutRef.current) {
													clearTimeout(
														toastTimeoutRef.current
													);
												}

												toastTimeoutRef.current =
													setTimeout(() => {
														toast(
															<Toast variant="error">
																Desmarque uma
																pergunta para
																selecionar!
															</Toast>
														);
													}, 1000);

												return;
											}
											setClose(!open);
										}}
									>
										Selecionar
									</Button>
								</div>

								<PlayersSelect
									id={item.id}
									open={open}
									onClose={() => setClose(!open)}
								>
									<div className="flex flex-col justify-between max-[500px]:px-3 px-6 pt-9 pb-11 lg:py-0 gap-4 max-h-[90vh]">
										<div className="flex flex-col gap-2 h-fit">
											<div>
												<span className="text-[#ececec] text-lg font-normal font-bahnschrift">
													{title}
												</span>
											</div>
											<div className="text-[#ececec] text-xs text-center font-normal font-bahnschrift">
												<span className="text-2xl font-bold font-bahnschrift">
													{numberReplySlider > 0
														? numberReplySlider
														: replyNumber}
												</span>
												{item.draftScout
													? (replyNumber ||
															numberReplySlider) <=
													  1
														? item.draftScout.name
														: item.draftScout
																.nameSuffix
													: ''}
											</div>

											<div className="relative px-5">
												<Slider.Root
													className="relative flex items-center select-none touch-none w-full h-6"
													defaultValue={[numberReply]}
													value={[numberReplySlider]}
													max={parseInt(
														item.numberMax
													)}
													min={parseInt(
														item.numberMin
													)}
													step={1}
													onValueChange={(value) => {
														setNumberReplySlider(
															value[0]
														);
														setNumberReplySelect(
															events.map(
																(event) => ({
																	...event,
																	teamHome: {
																		...event.teamHome,
																		scoreReply: 0,
																	},
																	teamAway: {
																		...event.teamAway,
																		scoreReply: 0,
																	},
																})
															)
														);
													}}
												>
													<Slider.Track className="bg-custom-white relative flex-grow rounded-full h-1.5">
														<Slider.Range className="absolute bg-custom-white rounded-full h-full" />
													</Slider.Track>
													<Slider.Thumb
														aria-label="Volume"
														className="block w-4 h-4 bg-custom-white rounded-lg focus:outline-none"
													></Slider.Thumb>
												</Slider.Root>
											</div>

											<div
												className={
													'flex flex-col gap-3 overflow-auto ' +
													layoutFormat()
												}
											>
												{numberReplySelect.map(
													(
														event: any,
														index: number
													) => (
														<div
															key={index}
															className="px-1.5 py-2 bg-custom-gray10 rounded-[5px] grid grid-flow-col items-center"
														>
															<div className="flex items-center justify-around gap-2 max-[500px]:gap-1">
																<div className="flex gap-1 flex-col w-6">
																	<Button
																		variant="delete"
																		onClick={() => {
																			setNumberReplySlider(
																				0
																			);
																			updateScore(
																				event.id,
																				'home',
																				'+'
																			);
																		}}
																	>
																		<RiAddLine className="w-4 h-4 text-black" />
																	</Button>
																	<Button
																		variant="delete"
																		onClick={() => {
																			setNumberReplySlider(
																				0
																			);
																			updateScore(
																				event.id,
																				'home',
																				'-'
																			);
																		}}
																	>
																		<RiSubtractLine className="w-4 h-4 text-black" />
																	</Button>
																</div>
																<div className="flex gap-2 max-[500px]:gap-1 justify-between w-full">
																	<div className="text-white bg-black w-8 h-10 justify-center flex items-center text-2xl font-bold font-bahnschrift leading-snug">
																		{event
																			.teamHome
																			?.scoreReply ===
																		0
																			? ''
																			: event
																					.teamHome
																					?.scoreReply}
																	</div>
																	<div className="flex flex-row items-center justify-end gap-2 max-[500px]:gap-1 max-[500px]:w-20 w-28">
																		<span className="text-custom-white text-right text-[11px] font-bold font-bahnschrift leading-snug">
																			{
																				event
																					.teamHome
																					.name
																			}
																		</span>
																		<img
																			className="max-[500px]:w-5 max-[500px]:h-5 w-6 h-6  object-scale-down"
																			src={
																				event
																					.teamHome
																					.image
																			}
																			alt={
																				event
																					.teamHome
																					.name
																			}
																		/>
																	</div>
																</div>
															</div>
															<div className="text-custom-white text-center flex-1 justify-center items-center">
																|
															</div>
															<div className="flex items-center justify-end gap-2 max-[500px]:gap-1">
																<div className="flex gap-2 max-[500px]:gap-1 justify-between w-full">
																	<div className="flex flex-row items-center justify-start gap-2 max-[500px]:gap-1 max-[500px]:w-20 w-28">
																		<img
																			className="max-[500px]:w-5 max-[500px]:h-5 w-6 h-6 object-scale-down"
																			src={
																				event
																					.teamAway
																					.image
																			}
																			alt={
																				event
																					.teamAway
																					.name
																			}
																		/>
																		<span className="text-custom-white text-left text-[11px] font-bold font-bahnschrift leading-snug">
																			{
																				event
																					.teamAway
																					.name
																			}
																		</span>
																	</div>
																	<div className="text-white bg-black w-8 h-10 justify-center items-center flex text-2xl font-bold font-bahnschrift leading-snug">
																		{event
																			.teamAway
																			?.scoreReply ===
																		0
																			? ''
																			: event
																					.teamAway
																					?.scoreReply}
																	</div>
																</div>
																<div className="flex gap-1 flex-col w-6">
																	<Button
																		variant="delete"
																		onClick={() => {
																			setNumberReplySlider(
																				0
																			);
																			updateScore(
																				event.id,
																				'away',
																				'+'
																			);
																		}}
																	>
																		<RiAddLine className="w-4 h-4 text-black" />
																	</Button>
																	<Button
																		variant="delete"
																		onClick={() => {
																			setNumberReplySlider(
																				0
																			);
																			updateScore(
																				event.id,
																				'away',
																				'-'
																			);
																		}}
																	>
																		<RiSubtractLine className="w-4 h-4 text-black" />
																	</Button>
																</div>
															</div>
														</div>
													)
												)}
											</div>
										</div>
										<div className="flex flex-row gap-4">
											<Button
												className="px-5 py-3 border border-custom-white"
												variant="black"
												textCustom="text-custom-white"
												onClick={() => {
													setClose(!open);
													setNumberReplySlider(0);
													// setNumberReplySelect(
													// 	events.map((event) => ({
													// 		...event,
													// 		teamHome: {
													// 			...event.teamHome,
													// 			scoreReply: 0,
													// 		},
													// 		teamAway: {
													// 			...event.teamAway,
													// 			scoreReply: 0,
													// 		},
													// 	}))
													// );
												}}
											>
												Fechar
											</Button>
											<Button
												className="w-full py-3"
												textCustom="text-custom-white"
												onClick={handleChangeNumber}
											>
												Finalizar
											</Button>
										</div>
									</div>
								</PlayersSelect>
							</div>
						);
					default:
						return null;
				}
			};
			const selectedItem = () => {
				if (item.optionType === 0 && statementReply === 'Yes') {
					return true;
				}
				if (
					item.optionType === 1 &&
					draftReply.length === parseInt(item.draftPlayersQtd)
				) {
					return true;
				}
				if (item.optionType === 2 && numberReply !== 0) {
					return true;
				}
				return false;
			};

			return (
				<div
					ref={ref}
					tabIndex={-1}
					key={index}
					className="flex flex-col w-full lg:max-w-screen-xl lg:mx-auto"
				>
					<div
						className={
							'bg-custom-white1 min-h-[116px] rounded-lg ' +
							(selectedItem() ? 'border-2 border-primary' : '')
						}
					>
						<div
							className={
								'absolute w-7 h-7 bg-primary rounded-tl-[5px] rounded-br-[9px] justify-center items-center inline-flex cursor-pointer ' +
								(selectedItem() ? '' : 'hidden')
							}
							onClick={() => removeChangeEnroll(item.id)}
						>
							<RiCloseLine className="w-4 h-4 justify-center items-center flex text-custom-white1" />
						</div>
						<div
							className="flex flex-col gap-0.5 px-4 py-1.5"
							onClick={() => {
								if (item.optionType === 0) {
									handleChangeStatement('Yes');
								}
								if (
									item.optionType === 1 ||
									item.optionType === 2
								) {
									if (blockSelection && !isSelected) {
										if (toastTimeoutRef.current) {
											clearTimeout(
												toastTimeoutRef.current
											);
										}

										toastTimeoutRef.current = setTimeout(
											() => {
												toast(
													<Toast variant="error">
														Desmarque uma pergunta
														opcional para selecionar
														uma nova pergunta.
													</Toast>
												);
											},
											1000
										);
										return;
									}
									setClose(!open);
								}
							}}
						>
							<div
								className={
									'self-stretch justify-between items-center gap-2.5 inline-flex ' +
									(selectedItem() ? 'pl-4' : '')
								}
							>
								<div className="text-[#9a9a9a] pt-0.5 text-[10px] font-semibold font-bahnschrift">
									{item.mandatory
										? 'Obrigatória'
										: 'Opcional'}
								</div>
								<div className="text-primary text-xs font-semibold font-bahnschrift">
									{item.winPoints} pontos
								</div>
							</div>
							<div className="self-stretch flex-col justify-start items-start gap-0.5 flex">
								<div
									className={
										'self-stretch flex-col justify-start items-start gap-1 flex' +
										(item.optionType !== 0
											? ''
											: ' min-h-12')
									}
								>
									<div className="self-stretch text-custom-black text-base font-semibold font-bahnschrift">
										{item.title}
									</div>
								</div>
							</div>

							{renderOption(
								item.optionType,
								item.title,
								item.teams
							)}

							{item.optionType !== 0 && item.mandatory && (
								<div className="self-stretch justify-start items-end gap-2.5 inline-flex">
									<div
										className={
											'text-[#9a9a9a] text-[10px] font-semibold font-bahnschrift flex gap-1' +
											(item.optionType === 1
												? ' mb-2.5'
												: ' relative -mt-2')
										}
									>
										{item.teams &&
											item.teams.length > 0 &&
											item.teams
												?.filter(
													(item: any) => item.image
												)
												.map(
													(
														team: any,
														index: number
													) => (
														<img
															key={index}
															className="w-6 h-6 object-scale-down"
															src={team.image}
															alt={team.name}
														/>
													)
												)}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			);
		}
	)
);

export default OptionItem;
