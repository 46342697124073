import { useEffect, useState } from 'react';
import { getMobilePlatform, headerHeight } from '../helpers/utils';
import Header from './Header';
import { NavBar } from './navbar';
import { useEnrollContext } from '../contexts/Enroll/EnrollContext';
import { Capacitor } from '@capacitor/core';
import { useNavigateStore } from '../store/navigation';
import { FutProIcon } from './Icons/FutProIcon';
import Button from './ui/button';

type LayoutProps = {
	children: React.ReactNode;
	customBG?: string;
	logoWhite?: boolean;
};

export const Layout = ({ children, customBG, logoWhite }: LayoutProps) => {
	const { enroll } = useEnrollContext();
	const [isVisible, setIsVisible] = useState(false);
	const [isAppInstalled, setIsAppInstalled] = useState(false);

	const dynamicPaddingBottom =
		enroll.length > 0
			? `${80 + enroll.length * 40}px`
			: Capacitor.getPlatform() === 'ios'
			? '65px'
			: '50px';
	const navigateState = useNavigateStore((state: any) => state.navigateState);
	const cameFromBackButton = useNavigateStore(
		(state: any) => state.cameFromBackButton
	);

	// useEffect(() => {
	// 	if (getMobilePlatform()) {
	// 		setIsVisible(true);
	// 	}
	// }, []);

	useEffect(() => {
		const scrollToTop = () => {
			const content = document.getElementById(
				'main-content'
			) as HTMLIonContentElement;
			content?.scrollToTop(0);
		};
		if (cameFromBackButton && navigateState) {
		} else {
			scrollToTop();
		}
	}, [location]);

	const handleInstallClick = () => {
		const appUrl = 'futpro://';
		const fallbackUrl =
			getMobilePlatform() === 'ios'
				? 'https://apps.apple.com/br/app/futpro/id6502291694'
				: 'https://play.google.com/store/apps/details?id=pga.app.play&pli=1';

		const timeout = setTimeout(() => {
			setIsAppInstalled(false);
			setIsVisible(false);
			window.location.href = fallbackUrl;
		}, 1500);

		const iframe = document.createElement('iframe');
		iframe.style.display = 'none';
		iframe.src = appUrl;
		document.body.appendChild(iframe);

		window.addEventListener('blur', () => {
			clearTimeout(timeout);
			setIsAppInstalled(true);
			setIsVisible(false);
		});

		setTimeout(() => {
			document.body.removeChild(iframe);
		}, 2000);
	};

	return (
		<div className="flex flex-col min-h-screen">
			<div className={`flex-grow ${headerHeight()} ${customBG || ''}`}>
				{isVisible && Capacitor.getPlatform() === 'web' && (
					<div
						className={`flex justify-between px-2 pt-2 items-center ${
							customBG || 'bg-white'
						}`}
					>
						<div className="flex flex-row justify-center items-center gap-2">
							<FutProIcon
								style="w-5 h-5"
								color={customBG ? '#ffffff' : '#DF1737'}
							/>
							<div className="flex flex-col">
								<span
									className={`text-sm font-bahnschrift ${
										customBG
											? 'text-white'
											: 'text-custom-gray3'
									}`}
								>
									FUTPro
								</span>
							</div>
						</div>

						<Button
							onClick={handleInstallClick}
							className="px-2.5 py-1.5 max-[320px]:px-2 text-xs"
						>
							Abrir aplicativo
						</Button>
					</div>
				)}
				<Header logoWhite={logoWhite} />
				{children}
			</div>

			<div style={{ paddingBottom: dynamicPaddingBottom }}>
				<NavBar />
			</div>
		</div>
	);
};
