import { RiArrowRightSLine } from '@remixicon/react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../../components/layout';
import ButtonBack from '../../../components/ui/buttonBack';
import {
	guidelinesResponsible,
	navigateResponsible,
} from '../../../data/responsibleGaming';
import { showIcon } from '../../../helpers/utilsUi';
import Button from '../../../components/ui/button';
import Footer from '../../../components/Footer';
import { useAuthContext } from '../../../contexts/Auth/AuthContext';
import { useModalLoginStore } from '../../../store/modalLogin';

import ebac from '../../../assets/jogo-responsavel.jpg';
import compulsafe from '../../../assets/compulsafe.jpg';

export const ResponsibleGaming = () => {
	const navigate = useNavigate();
	const { user } = useAuthContext();
	const { openModalLogin } = useModalLoginStore();

	const goToNavigate = (route: string) => {
		if (!user) {
			openModalLogin(true, route);
			return;
		} else {
			navigate(route);
		}
	};

	return (
		<Layout>
			<div className="px-3 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between py-2 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
					<ButtonBack classNameIcon="" />
					<p className="text-xl font-semibold text-custom-gray1 font-bahnschrift">
						Jogo Responsável
					</p>
					<div></div>
				</div>

				<div className="flex flex-col gap-10 mt-4 mb-[65px]">
					<img
						alt="EBAC"
						src={ebac}
						className="rounded-lg w-full h-64 lg:h-80 object-cover"
					></img>

					<div className="flex flex-col gap-6">
						<div className="">
							<span className="text-black text-base font-normal font-bahnschrift leading-tight">
								No{' '}
							</span>
							<span className="text-black text-base font-bold font-bahnschrift leading-tight">
								Futpro
							</span>
							<span className="text-black text-base font-normal font-bahnschrift leading-tight">
								{' '}
								acreditamos na importância do jogo responsável e
								nos esforçamos para garantir que nossos
								jogadores desfrutem de uma experiência de
								diversão{' '}
							</span>
							<span className="text-black text-base font-bold font-bahnschrift leading-tight">
								segura e saudável
							</span>
							<span className="text-black text-base font-normal font-bahnschrift leading-tight">
								.
							</span>
						</div>
						<div className="flex flex-col gap-2">
							<div className="text-black text-xl font-semibold font-bahnschrift leading-7">
								Ajudando você a manter o controle.
							</div>
							<div className="flex flex-col gap-4">
								{navigateResponsible.map((item, index) => (
									<div
										key={index}
										onClick={() => {
											goToNavigate(item.route);
										}}
										className="p-4 bg-custom-white1 rounded-lg justify-between items-center inline-flex cursor-pointer"
									>
										<div className="justify-start items-center gap-[17px] flex">
											<div className="w-[30px] flex items-center justify-center">
												{showIcon(item.icon)}
											</div>

											<div className="flex-col justify-start items-start gap-2 inline-flex">
												<div className="text-black text-sm font-semibold font-bahnschrift leading-tight">
													{item.title}
												</div>
												<div className="text-black text-xs font-light font-bahnschrift leading-none">
													{item.description}
												</div>
											</div>
										</div>
										<RiArrowRightSLine className="h-5 relative opacity-70" />
									</div>
								))}
							</div>
						</div>
						<div className="flex flex-col justify-center items-center gap-4">
							<div className="flex flex-col justify-center items-center gap-2">
								<div className="opacity-80 text-center text-black text-xl font-semibold font-bahnschrift leading-7">
									Precisa de Ajuda?
								</div>
								<div className="opacity-80 px-5 text-center text-black text-sm font-normal font-bahnschrift leading-tight">
									Se o jogo se tornou um problema em sua vida,
									A EBAC está pronta para lhe dar a mão.
								</div>
							</div>
							<Button
								onClick={() => {
									const link = document.createElement('a');
									link.href =
										'https://ebacbrasil.com.br/futpro/';
									link.download = 'provablyFair.txt';
									link.click();
								}}
								className="w-[227px] p-3.5"
							>
								Quero Ajuda
							</Button>
						</div>
						<div className="flex flex-col gap-4">
							<div className="">
								<span className="text-black text-base font-normal font-bahnschrift leading-tight">
									Abaixo estão os princípios e diretrizes que
									orientam nossa abordagem em relação ao{' '}
								</span>
								<span className="text-black text-base font-bold font-bahnschrift leading-tight">
									jogo responsável
								</span>
								<span className="text-black text-base font-normal font-bahnschrift leading-tight">
									:
								</span>
							</div>
							<div className="flex flex-col gap-4">
								{guidelinesResponsible
									.sort(
										(a, b) =>
											Number(a.position) -
											Number(b.position)
									)
									.map((item, index) => (
										<div
											key={index}
											className="p-4 bg-custom-white1 rounded-lg justify-start items-center gap-4 inline-flex"
										>
											<div className="w-7 text-primary text-2xl font-normal font-bahnschrift leading-loose text-center">
												{item.position}
											</div>
											<div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
												<div className="self-stretch text-black text-sm font-semibold font-bahnschrift leading-tight">
													{item.title}
												</div>
												<div className="self-stretch text-black text-xs font-light font-bahnschrift leading-none">
													{item.description}
												</div>
											</div>
										</div>
									))}
							</div>
							<div className="flex flex-col gap-4 mt-4">
								{/* <div className="grid grid-cols-2 items-center justify-center"> */}
								<div className="bg-ebac w-[92px] h-7 mx-auto"></div>
								{/* <img
										src={compulsafe}
										className="w-[92px] mx-auto"
									/>
								</div> */}

								<div className="">
									<span className="text-black text-base font-normal font-bahnschrift leading-tight">
										Estamos comprometidos em proporcionar um
										ambiente de{' '}
									</span>
									<span className="text-black text-base font-bold font-bahnschrift leading-tight">
										jogo seguro
									</span>
									<span className="text-black text-base font-normal font-bahnschrift leading-tight">
										,{' '}
									</span>
									<span className="text-black text-base font-bold font-bahnschrift leading-tight">
										saudável
									</span>
									<span className="text-black text-base font-normal font-bahnschrift leading-tight">
										{' '}
										e{' '}
									</span>
									<span className="text-black text-base font-bold font-bahnschrift leading-tight">
										responsável
									</span>
									<span className="text-black text-base font-normal font-bahnschrift leading-tight">
										{' '}
										para todos os nossos jogadores. Em caso
										de dúvidas ou necessidade de suporte
										adicional, não hesite em entrar em
										contato conosco.
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</Layout>
	);
};
