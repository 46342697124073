import { Logo } from '../Logo';
import {
	RiDiscordLine,
	RiFacebookLine,
	RiInstagramLine,
	RiLinkedinLine,
	RiThreadsLine,
	RiTiktokLine,
	RiTwitterXFill,
} from '@remixicon/react';
import { Capacitor } from '@capacitor/core';
import { useNavigate } from 'react-router-dom';

import appStore from '../../assets/appstore.png';
import googlePlay from '../../assets/google.png';
import android from '../../assets/android.png';

const Footer = () => {
	const display = Capacitor.isNativePlatform();
	const navigate = useNavigate();

	return (
		<div className={'w-full bg-footer-light py-6'}>
			<div
				className={
					'flex justify-between items-center px-7 lg:max-w-screen-xl lg:mx-auto ' +
					(display ? ' pb-8' : '')
				}
			>
				<a
					onClick={() => {
						navigate('/');
					}}
					className="cursor-pointer"
				>
					<Logo />
				</a>
				<div className="flex flex-row justify-center gap-3">
					<a
						href="https://www.instagram.com/fpro_br/"
						target="_blank"
					>
						<RiInstagramLine
							size={22}
							color="#1d1f24"
							name="ChevronRight"
							className="w-5 h-5 lg:w-7 lg:h-7"
						/>
					</a>
					<a href="https://www.tiktok.com/@fpro_br" target="_blank">
						<RiTiktokLine
							size={22}
							color="#1d1f24"
							name="ChevronRight"
							className="w-5 h-5 lg:w-7 lg:h-7"
						/>
					</a>
					<a href="https://x.com/fpro_br" target="_blank">
						<RiTwitterXFill
							size={22}
							color="#1d1f24"
							name="ChevronRight"
							className="w-5 h-5 lg:w-7 lg:h-7"
						/>
					</a>
					<a
						href="https://www.threads.net/@fpro_br?hl=pt-br"
						target="_blank"
					>
						<RiThreadsLine
							size={22}
							color="#1d1f24"
							name="ChevronRight"
							className="w-5 h-5 lg:w-7 lg:h-7"
						/>
					</a>
					<a
						href="https://www.facebook.com/profile.php?id=61569194031311&locale=pt_BR"
						target="_blank"
					>
						<RiFacebookLine
							size={22}
							color="#1d1f24"
							name="ChevronRight"
							className="w-5 h-5 lg:w-7 lg:h-7"
						/>
					</a>
					<a
						href="https://www.linkedin.com/company/fprobr/"
						target="_blank"
					>
						<RiLinkedinLine
							size={22}
							color="#1d1f24"
							name="ChevronRight"
							className="w-5 h-5 lg:w-7 lg:h-7"
						/>
					</a>
					<a href="https://discord.gg/P4K5V7TtSh" target="_blank">
						<RiDiscordLine
							size={22}
							color="#1d1f24"
							name="ChevronRight"
							className="w-5 h-5 lg:w-7 lg:h-7"
						/>
					</a>
				</div>
			</div>

			{!display && (
				<div className="px-6 py-6 lg:max-w-screen-xl lg:mx-auto lg">
					<div className="flex flex-row gap-4 justify-end">
						<a
							href="https://apps.apple.com/br/app/futpro/id6502291694"
							target="_blank"
							className="cursor-pointer text-sm text-left text-custom-gray1 font-bahnschrift"
						>
							<img
								src={appStore}
								className="h-[32px] lg:h-[40px] object-scale-down"
							/>
						</a>
						<a
							href="https://play.google.com/store/apps/details?id=pga.app.play&pli=1"
							target="_blank"
							className="cursor-pointer text-sm text-left text-custom-gray1 font-bahnschrift"
						>
							<img
								src={googlePlay}
								className="h-[32px] lg:h-[40px] object-scale-down"
							/>
						</a>
						<a
							href="https://pga-images.s3.us-east-1.amazonaws.com/app/FUTPRO.apk"
							target="_blank"
							className="cursor-pointer text-sm text-left text-custom-gray1 font-bahnschrift"
						>
							<img
								src={android}
								className="h-[32px] lg:h-[40px] object-scale-down"
							/>
						</a>
					</div>
				</div>
			)}

			<div className="px-6 lg:max-w-screen-xl lg:mx-auto">
				<div className="grid grid-cols-2 gap-7 pb-4 lg:grid-cols-6">
					<div className="flex flex-col gap-4">
						<a
							onClick={() => {
								navigate('/termsofuse');
							}}
							className="cursor-pointer text-sm text-left text-custom-gray1 font-bahnschrift"
						>
							Termos de Uso
						</a>
						<a
							onClick={() => {
								navigate('/privacypolicy');
							}}
							className="cursor-pointer text-sm text-left text-custom-gray1 font-bahnschrift"
						>
							Política de Privacidade
						</a>
						<a
							onClick={() => {
								navigate('/transparency');
							}}
							className="cursor-pointer text-sm text-left text-custom-gray1 font-bahnschrift"
						>
							Portal de Transparência
						</a>
						<a
							onClick={() => {
								navigate('/rules');
							}}
							className="cursor-pointer text-sm text-left text-custom-gray1 font-bahnschrift"
						>
							Regulamento
						</a>
					</div>
					<div className="flex flex-col gap-4">
						<a
							onClick={() => {
								navigate('/help');
							}}
							className="cursor-pointer text-sm text-left text-custom-gray1 font-bahnschrift"
						>
							Ajuda
						</a>
						<a
							onClick={() => {
								navigate('/jogo-responsavel');
							}}
							className="cursor-pointer text-sm text-left text-custom-gray1 font-bahnschrift"
						>
							Jogo Responsável
						</a>
						<a
							onClick={() => {
								window.zE('messenger', 'open');
							}}
							className="cursor-pointer text-sm text-left text-custom-gray1 font-bahnschrift"
						>
							Suporte
						</a>
					</div>
				</div>
			</div>

			<div className="h-0 border-solid border-custom-gray3 opacity-25 border-b col-span-4 mx-6 my-4 lg:max-w-screen-xl lg:mx-auto "></div>

			<div className="px-6 lg:max-w-screen-xl lg:mx-auto ">
				<p className="text-sm text-left text-[#6d6d6d] font-bahnschrift">
					Garantindo que cumprimos os mais altos padrões de
					integridade e segurança em nossos serviços. Estamos aqui
					para você, prontos para ajudar e esclarecer qualquer dúvida
					que possa surgir, para que você tenha uma excelente
					experiência conosco!
				</p>
			</div>

			<div className="h-0 border-solid border-custom-gray3 opacity-25 border-b col-span-4 mx-6 my-4 lg:max-w-screen-xl lg:mx-auto "></div>

			<div className="flex flex-col gap-3 justify-center items-center">
				<div className=" text-center text-[#949494] text-sm font-bold font-bahnschrift leading-3">
					Jogo Responsável
				</div>
				<div className=" text-[#949494] text-xs font-normal font-bahnschrift leading-3">
					Acreditamos na importância do jogo responsável.
				</div>
				<div
					onClick={() => {
						navigate('/jogo-responsavel', { replace: true });
					}}
					className="w-40 h-[38.36px] px-[33.82px] py-[5.76px] bg-black/10 rounded-lg justify-center items-center inline-flex cursor-pointer"
				>
					<div className="w-[93.55px] h-[26.85px] relative bg-ebac-white"></div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
