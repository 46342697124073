import { CurrencyFormatter } from '../../helpers/utils';
import { Award } from '../../types/types';

interface TableAwardProps {
	award: Award;
	prizeTotal: any;
	tournamentStatus?: any;
	registeredEnrolls: number;
}
export const TableAward = ({
	award,
	prizeTotal,
	registeredEnrolls,
}: TableAwardProps) => {
	const uniquePlacings: any = [];

	const trackRegistrations = award.trackRegistrations.filter(
		(i) => Number(i.qtdFinalRegistrations) >= Number(registeredEnrolls)
	);
	trackRegistrations.forEach((item) => {
		item.awards.forEach((award) => {
			const placing =
				award.placingInitial === award.placingFinal
					? `${award.placingInitial}º Lugar`
					: `${award.placingInitial}º a ${award.placingFinal}º`;
			if (!uniquePlacings.includes(placing)) {
				uniquePlacings.push(placing);
			}
		});
	});

	return (
		<div className="overflow-x-auto w-full">
			<table className="min-w-max table-auto border-collapse bg-white border border-stone-300/30">
				<thead className="border-b border-[#bcc0c4]">
					<tr>
						<th className="p-2 w-14 text-left self-stretch text-[#73777c] text-[12px] font-medium font-bahnschrift leading-3 tracking-tight">
							Inscrições
						</th>
						{trackRegistrations.map((item, index) => (
							<th
								key={index}
								className="py-2 max-w-48 text-center self-stretch text-[#73777c] text-[12px] font-medium font-bahnschrift leading-3 tracking-tight"
							>
								{item.qtdInitialRegistrations} a{' '}
								{item.qtdFinalRegistrations}
							</th>
						))}
					</tr>
				</thead>
				<thead className="border-b border-[#bcc0c4]">
					<tr>
						<th className="p-2 w-14 text-left self-stretch text-[#73777c] text-[12px] font-medium font-bahnschrift leading-3 tracking-tight">
							Prêmios
						</th>
						{trackRegistrations.map((item, index) => (
							<th
								key={index}
								className="py-2 max-w-48 text-center self-stretch text-[#73777c] text-[12px] font-medium font-bahnschrift leading-3 tracking-tight"
							>
								{item.qtdWinners}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{uniquePlacings.map((placing: any, rowIndex: number) => (
						<tr className="h-7" key={rowIndex}>
							<td className="p-2 w-14 text-left self-stretch text-black text-[12px] font-medium font-bahnschrift leading-[10px]">
								{placing}
							</td>
							{trackRegistrations.map((item, colIndex) => {
								const award = item.awards.find(
									(a) =>
										`${
											a.placingInitial === a.placingFinal
												? `${a.placingInitial}º Lugar`
												: `${a.placingInitial}º a ${a.placingFinal}º`
										}` === placing
								);
								return (
									<td
										className="w-28 py-1 text-center self-stretch text-black text-[14px] font-medium font-bahnschrift leading-[10px]"
										key={colIndex}
									>
										<div className="flex flex-col gap-0.5">
											{award
												? `R$ ${CurrencyFormatter(
														(parseFloat(
															award.percentAward
														) /
															100) *
															Number(prizeTotal)
												  )}`
												: ''}
											<span className="text-[9px] font-medium text-[#73777c] font-bahnschrift">
												{award
													? `(${award.percentAward}%)`
													: ''}
											</span>
										</div>
									</td>
								);
							})}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
