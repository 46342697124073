import { useEffect, useState } from 'react';
import Footer from '../../../components/Footer';
import { Layout } from '../../../components/layout';
import { useUserProfileStore } from '../../../store/user';
import { useBalanceStore } from '../../../store/balance';
import { Alert } from '../../../components/Alert';
import { useNavigate } from 'react-router-dom';
import { PhoneUpdate } from '../../../components/Modal/Update/Phone';
import { sentryCapture, showWalletAndBalance } from '../../../helpers/utils';
import { ChannelTypes, SentryError } from '../../../types/enums';
import { useApi } from '../../../hooks/useApi';
import { useStream } from '../../../contexts/GetStream/StreamContext';
import FeedBanner from '../../../components/FeedBanner';
import FeedBody from '../../../components/FeedBody';
import { useFeedUpdateStore } from '../../../store/feedUpdate';
import DialogFeed from '../../../components/DialogFeed';
import { useAuthContext } from '../../../contexts/Auth/AuthContext';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

export const FeedLogged = () => {
	const api = useApi();
	const navigate = useNavigate();
	const { updateProfile } = useAuthContext();
	const { updateFeed, updateFeedFinish } = useStream();
	const { feedupdate, finishUpdateFeed } = useFeedUpdateStore();
	const userData = useUserProfileStore((state: any) => state.userData);
	const currentBalance = useBalanceStore((state) => state.balance);
	const [modalUpdatePhone, setModalUpdatePhone] = useState(false);
	const [feeds, setFeeds] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<any>([]);

	useEffect(() => {
		updateProfile();
		fetchFeed();
	}, []);

	useEffect(() => {
		if (updateFeed || feedupdate) {
			fetchFeed();
		}
	}, [updateFeed, feedupdate]);

	useEffect(() => {
		if (Capacitor.isNativePlatform()) {
			const setupListener = async () => {
				const handle = await App.addListener(
					'appStateChange',
					(appState) => {
						if (appState.isActive) {
							fetchFeed();
						}
					}
				);
				return handle;
			};

			const listenerPromise = setupListener();
			return () => {
				listenerPromise.then((handle) => handle.remove());
			};
		}
	}, [App]);

	const fetchFeed = async () => {
		setIsLoading(true);
		try {
			const res = await api.getFeed();
			if (!res?.success) {
				throw new Error(res.message);
			}
			setFeeds(res.data);
			finishUpdateFeed();
		} catch (error: any) {
			sentryCapture(error, 'fetchFeed', SentryError.Error);
		} finally {
			setIsLoading(false);
			updateFeedFinish();
		}
	};
	const feedBanner = feeds.filter(
		(feed: any) => feed.channel === ChannelTypes.HOMEHEAD
	);
	const feedBody = feeds.filter(
		(feed: any) => feed.channel === ChannelTypes.HOMEBODY
	);

	return (
		<Layout>
			<div className="w-full lg:max-w-screen-xl lg:mx-auto lg:pt-10 ">
				{!userData?.phone && (
					<Alert
						textInfo="Cadastre o seu telefone"
						onClick={() => {
							setModalUpdatePhone(!modalUpdatePhone);
						}}
					/>
				)}
				{parseFloat(currentBalance) < 10 && showWalletAndBalance() && (
					<Alert
						textInfo="Depositar"
						onClick={() => navigate('/wallet/deposit')}
					/>
				)}

				<div className="flex flex-col gap-3 py-2.5">
					<div className="px-0.5">
						<FeedBanner data={feedBanner} isLoading={isLoading} />
					</div>
					<div className="grid grid-flow-row gap-3 px-0.5">
						<FeedBody data={feedBody} isLoading={isLoading} />
					</div>
				</div>
			</div>

			<DialogFeed />
			<PhoneUpdate
				open={modalUpdatePhone}
				onClose={() => {
					setModalUpdatePhone(!modalUpdatePhone);
				}}
			/>
			<Footer />
		</Layout>
	);
};
