import { useEffect, useLayoutEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IonContent, IonPage, setupIonicReact } from '@ionic/react';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app';
import { Toaster } from 'sonner';
import Navigation from './Router/routes';
import { CookieProvider } from './contexts/Cookies/CookiesContext';
import { UpdateVersionProvider } from './contexts/UpdateVersion/UpdateVersion';
import { PromoProvider } from './contexts/PromoCode/PromoContext';
import { AuthProvider } from './contexts/Auth/AuthProvider';
import { StreamProvider } from './contexts/GetStream/StreamContext';
import { EnrollProvider } from './contexts/Enroll/EnrollContext';
import AppUrlListener from './components/AppUrlListener';
import { KeepAlive } from './components/KeepAlive';
import { useScrollControllStore } from './store/scrollControl';
import {
	initializeOneSignal,
	initializePermission,
	initializeLiveUpdates,
} from './helpers/initializers';
import { SafeArea } from 'capacitor-plugin-safe-area';

import '@ionic/react/css/core.css';

setupIonicReact();

const APP_ENV = process.env.REACT_APP_ENV;
const oneSignalKey =
	APP_ENV === 'DEV'
		? '13d8a44b-09bf-4b90-881c-43d8180bdc19'
		: APP_ENV === 'HML'
		? '575b0cd8-d50f-43d4-89cd-badbf37a75d2'
		: '8354282b-7378-4252-b706-c96020faf35f';

export default function App() {
	const { setScrollEvent } = useScrollControllStore();
	let statusBarHeight = 0;

	useLayoutEffect(() => {
		const initializeApp = async () => {
			if (Capacitor.isNativePlatform()) {
				await SplashScreen.show();
				const safeAreaData = await SafeArea.getSafeAreaInsets();
				const { insets } = safeAreaData;
				for (const [key, value] of Object.entries(insets)) {
					document.documentElement.style.setProperty(
						`--safe-area-inset-${key}`,
						`${value}px`
					);
				}
			}
			await initializeOneSignal(oneSignalKey);
			await initializePermission();
			await initializeLiveUpdates();
		};
		initializeApp();
	}, []);

	const verifyUpdateAppAfterReturnBackground = async () => {
		await SplashScreen.show();
		await initializeLiveUpdates();
	};

	useEffect(() => {
		if (Capacitor.isNativePlatform()) {
			const setupListener = async () => {
				const handle = await CapacitorApp.addListener(
					'appStateChange',
					(appState) => {
						if (appState.isActive) {
							verifyUpdateAppAfterReturnBackground();
						}
					}
				);
				return handle;
			};

			const listenerPromise = setupListener();
			return () => {
				listenerPromise.then((handle) => handle.remove());
			};
		}
	}, [CapacitorApp]);

	const handleScroll = (event: CustomEvent) => {
		setScrollEvent(event.detail.scrollTop);
	};

	return (
		<IonPage
			className={
				Capacitor.getPlatform() === 'ios' ? 'pt-safe toolbar' : ''
			}
		>
			<IonContent
				id="main-content"
				className="overflow-hidden"
				scrollEvents={true}
				onIonScroll={handleScroll}
			>
				<BrowserRouter>
					<AppUrlListener />
					<Toaster
						richColors
						position="top-center"
						toastOptions={{ duration: 3000, unstyled: true }}
						className="mt-11"
						visibleToasts={1}
						expand
					/>
					<CookieProvider>
						<UpdateVersionProvider>
							<PromoProvider>
								<AuthProvider>
									<StreamProvider>
										<EnrollProvider>
											<KeepAlive>
												<Navigation />
											</KeepAlive>
										</EnrollProvider>
									</StreamProvider>
								</AuthProvider>
							</PromoProvider>
						</UpdateVersionProvider>
					</CookieProvider>
				</BrowserRouter>
			</IonContent>
		</IonPage>
	);
}
