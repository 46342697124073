import { useState, useRef, useEffect, forwardRef } from 'react';
import { RiErrorWarningLine, RiEyeFill, RiEyeOffFill } from '@remixicon/react';
import { IMaskInput } from 'react-imask';

export interface ITextInputProps {
	type: 'text' | 'password' | 'number';
	inputMode?:
		| 'none'
		| 'text'
		| 'tel'
		| 'url'
		| 'email'
		| 'numeric'
		| 'decimal'
		| 'search'
		| undefined;
	placeholder?: string;
	error?: string;
	value?: any;
	onChange: (value: string) => void;
	mask?: any;
	blocks?: any;
	disabled?: boolean;
	autoFocus?: boolean;
	classNameCustom?: string;
}

const TextInput2 = forwardRef<HTMLInputElement, ITextInputProps>(
	(
		{
			type,
			placeholder,
			inputMode,
			error,
			value,
			onChange,
			mask,
			blocks,
			disabled,
			autoFocus,
			classNameCustom,
		},
		ref
	) => {
		const [showPassword, setShowPassword] = useState(false);
		const [isFocused, setIsFocused] = useState(false);
		const inputRef = useRef<HTMLInputElement>(null);

		useEffect(() => {
			if (autoFocus && inputRef.current) {
				inputRef.current.focus();
			}
		}, [autoFocus]);

		const togglePasswordVisibility = () => {
			setShowPassword(!showPassword);
			if (inputRef.current) {
				inputRef.current.focus();
			}
		};

		const handleFocus = () => {
			setIsFocused(true);
		};

		const handleBlur = () => {
			setIsFocused(false);
		};

		const customInputErrorColor = error
			? 'border-error focus:outline-none focus:ring-0 focus:border-error px-2.5 py-2.5 h-12 disabled:bg-custom-white'
			: 'border-custom-gray3 focus:outline-none focus:ring-0 focus:border-info px-2.5 pt-2 h-12 disabled:bg-custom-white';

		const customLabelErrorColor = error
			? 'text-error peer-focus:text-error disabled:bg-custom-white'
			: 'text-custom-gray3 peer-focus:text-custom-gray4 disabled:bg-custom-white';

		return (
			<div className="relative">
				{mask ? (
					<IMaskInput
						mask={mask}
						radix=","
						mapToRadix={['.']}
						scale={2}
						blocks={blocks}
						unmask={true}
						thousandsSeparator="."
						padFractionalZeros={true}
						normalizeZeros={true}
						value={value}
						onAccept={(val) => onChange(val)}
						type={
							type === 'password' && !showPassword
								? 'password'
								: 'text'
						}
						inputMode={inputMode}
						id="floating_filled4"
						className={`block peer w-full text-sm rounded-md border appearance-none font-bahnschrift ${customInputErrorColor} ${classNameCustom}`}
						placeholder={''}
						disabled={disabled}
						onFocus={handleFocus}
						onBlur={handleBlur}
						inputRef={ref || inputRef}
						autoFocus={autoFocus}
					/>
				) : (
					<input
						type={
							type === 'password' && !showPassword
								? 'password'
								: 'text'
						}
						id="floating_filled4"
						className={`block peer w-full text-sm rounded-md border appearance-none font-bahnschrift ${customInputErrorColor} ${classNameCustom}`}
						placeholder={''}
						inputMode={inputMode}
						value={value}
						onChange={(e) => onChange(e.target.value)}
						disabled={disabled}
						onFocus={handleFocus}
						onBlur={handleBlur}
						ref={ref || inputRef}
						autoFocus={autoFocus}
					/>
				)}
				<label
					htmlFor="floating_filled4"
					className={`absolute text-sm duration-300 transform font-bahnschrift -translate-y-4 scale-75 top-3.5 z-10 origin-[0] start-2.5 ${
						value || isFocused
							? 'scale-75 -translate-y-4'
							: 'scale-100 translate-y-0'
					} ${customLabelErrorColor}`}
					style={{ pointerEvents: 'none' }}
				>
					{placeholder}
				</label>
				{type === 'password' && (
					<button
						type="button"
						className={
							'absolute inset-y-0 right-0 z-40 flex items-center px-4 text-gray-400 cursor-pointer' +
							(error && ' -mt-4')
						}
						onClick={togglePasswordVisibility}
					>
						{showPassword ? (
							<RiEyeFill
								size={20}
								color="#A3A5AB"
								className="ml-3"
							/>
						) : (
							<RiEyeOffFill
								size={20}
								color="#A3A5AB"
								className="ml-3"
							/>
						)}
					</button>
				)}

				{error && (
					<div
						className={`absolute inset-y-0 right-0 flex items-center text-error ${
							type === 'password' ? 'pr-10 -mt-4' : 'pr-4  -mt-4'
						}`}
					>
						<RiErrorWarningLine
							size={20}
							color="#c13939"
							className="ml-3"
						/>
					</div>
				)}

				{error && (
					<p className="flex text-error text-xs pl-1 font-bahnschrift pt-0.5">
						{error}
					</p>
				)}
			</div>
		);
	}
);

export default TextInput2;
