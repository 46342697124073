import { Capacitor } from '@capacitor/core';
import { Rule as iRule, LeaguesTournament } from '../../../../types/types';
import { TableAward } from '../../../../components/TableAward';
import TabsInTournament from '../../../../components/TabsInTournament';

interface iRules {
	data: iRule;
}

export const Rule = ({ data }: iRules) => {
	return (
		<div className="z-0 bg-[#f4f4f4] p-2 rounded-lg">
			<div
				className="w-full h-full"
				dangerouslySetInnerHTML={{
					__html:
						Capacitor.getPlatform() === 'ios'
							? data.contentIOS
							: Capacitor.getPlatform() === 'android'
							? data.contentAndroid
							: data.content,
				}}
			/>
		</div>
	);
};
