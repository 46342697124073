import { useRef, useEffect, memo } from 'react';
import { LeaguesTournament } from '../../types/types';
import { useEnrollContext } from '../../contexts/Enroll/EnrollContext';
import { RiCloseLine } from '@remixicon/react';
import { TournamentTypeDisplay } from '../../types/enums';
import { CurrencyFormatter, showWalletAndBalance } from '../../helpers/utils';
import { FutProIcon } from '../Icons/FutProIcon';
import { UserGroup } from '../Icons/UserGroup';
import { Max } from '../Icons/Max';
import { Min } from '../Icons/Min';

interface TabsProps {
	data: LeaguesTournament[] | undefined;
	tournamentStatus: number | undefined;
}

const Carousel = memo(({ data, tournamentStatus }: TabsProps) => {
	const { select, selectEnroll } = useEnrollContext();
	const tabListRef = useRef<HTMLUListElement>(null);
	const activeTabRef = useRef<HTMLLIElement>(null);

	const handleChange = (data: any) => {
		selectEnroll({
			leagueId: data.league.id,
			leagueName: data.league.title,
			entryAmt: data.entryAmt,
			prizeAmt: data.prizeAmt,
		});
	};

	useEffect(() => {
		if (activeTabRef.current && tabListRef.current) {
			const activeTab = activeTabRef.current;
			const tabList = tabListRef.current;
			const activeTabRect = activeTab.getBoundingClientRect();
			const tabListRect = tabList.getBoundingClientRect();

			if (activeTabRect.left < tabListRect.left) {
				tabList.scrollLeft -= tabListRect.left - activeTabRect.left;
			} else if (activeTabRect.right > tabListRect.right) {
				tabList.scrollLeft += activeTabRect.right - tabListRect.right;
			}
		}
	}, []);

	let isDragging = false;
	let startX: number;
	let scrollLeft: number;

	const handleMouseDown = (e: React.MouseEvent) => {
		isDragging = true;
		startX = e.pageX - (tabListRef.current?.offsetLeft || 0);
		scrollLeft = tabListRef.current?.scrollLeft || 0;
		document.body.style.userSelect = 'none';
	};
	const handleMouseLeave = () => {
		isDragging = false;
		document.body.style.userSelect = '';
	};
	const handleMouseUp = () => {
		isDragging = false;
		document.body.style.userSelect = '';
	};
	const handleMouseMove = (e: React.MouseEvent) => {
		if (!isDragging) return;
		e.preventDefault();
		const x = e.pageX - (tabListRef.current?.offsetLeft || 0);
		const walk = (x - startX) * 2;
		if (tabListRef.current) {
			tabListRef.current.scrollLeft = scrollLeft - walk;
		}
	};

	return (
		<div className="flex flex-col w-full lg:max-w-screen-xl lg:mx-auto">
			<ul
				className="flex gap-1 mb-0 list-none flex-row overflow-x-auto whitespace-nowrap scrollbar-hide"
				role="tablist1"
				ref={tabListRef}
				onMouseDown={handleMouseDown}
				onMouseLeave={handleMouseLeave}
				onMouseUp={handleMouseUp}
				onMouseMove={handleMouseMove}
			>
				{data?.map((tabsTitle, index) => (
					<li
						key={index}
						className="flex flex-shrink-0 cursor-pointer select-none font-bahnschrift"
						ref={
							select.includes(tabsTitle.league.id)
								? activeTabRef
								: null
						}
					>
						<a
							onClick={(e) => {
								e.preventDefault();
								if (tournamentStatus === 0) {
									handleChange(tabsTitle);
								}
							}}
							data-toggle="tab"
							href={`#${tabsTitle.league.title}`}
							role="tablist1"
							aria-selected={select.includes(tabsTitle.league.id)}
						>
							{tabsTitle.content && (
								<div
									className={
										'w-full h-auto rounded-lg relative z-10 ' +
										(tournamentStatus === 0 &&
										select.includes(tabsTitle.league.id)
											? 'border-[3px] border-primary'
											: '')
									}
								>
									<div
										className={
											'absolute z-20 right-0 flex w-6 h-6 bg-primary rounded-bl-lg justify-center items-center cursor-pointer ' +
											(tournamentStatus === 0 &&
											select.includes(tabsTitle.league.id)
												? ''
												: 'hidden')
										}
									>
										<RiCloseLine className="w-4 h-4 justify-center items-center flex text-[#fff]" />
									</div>

									<div className="relative w-full h-auto">
										<picture>
											<source
												srcSet={tabsTitle.content.image}
												media="(max-width: 767px)"
												className="h-36 w-[150px] object-cover rounded z-0"
											/>
											<source
												srcSet={tabsTitle.content.image}
												media="(max-width: 768px)"
												className="h-36 w-[150px] object-cover rounded z-0"
											/>
											<source
												srcSet={
													tabsTitle.content.imageWeb
												}
												media="(max-width: 1024px)"
												className="h-36 w-[150px] object-cover rounded z-0"
											/>
											<img
												className="h-36 w-[150px] object-cover rounded z-0"
												alt={tabsTitle.content.title}
												src={tabsTitle.content.imageWeb}
											/>
										</picture>

										{TournamentTypeDisplay[
											tournamentStatus!
										] === 'Inscrições abertas' &&
										tabsTitle.content.html ? (
											<div
												className="absolute inset-0 w-full h-full flex flex-col justify-between z-10 "
												dangerouslySetInnerHTML={{
													__html: tabsTitle.content
														.html,
												}}
											/>
										) : TournamentTypeDisplay[
												tournamentStatus!
										  ] !== 'Inscrições abertas' &&
										  tabsTitle.content.htmlClosed ? (
											<div
												className="absolute inset-0 w-full h-full flex flex-col justify-between z-10 "
												dangerouslySetInnerHTML={{
													__html: tabsTitle.content
														.htmlClosed,
												}}
											/>
										) : (
											<div className="absolute inset-0 w-full h-full flex flex-col justify-between z-10">
												<div className="h-full w-full rounded-lg p-1 flex-col justify-end items-start inline-flex ">
													<div className="w-full bg-custom-black/[0.79] py-1 rounded">
														<div className="flex-col justify-center items-start gap-[1px] flex">
															<div className="text-[#fff] font-bahnschrift text-[10px] px-2.5">
																Prêmio de:
															</div>
															<div className="text-[#fff] font-bahnschrift text-[17px] px-2.5">
																<span className="flex gap-1 items-center">
																	{showWalletAndBalance() ? (
																		'R$ '
																	) : (
																		<FutProIcon
																			style="w-3.5 h-3.5"
																			color="#ffffff"
																		/>
																	)}
																	{CurrencyFormatter(
																		Number(
																			tabsTitle.prizeAmt
																		)
																	)}
																</span>
															</div>

															{TournamentTypeDisplay[
																tournamentStatus!
															] ===
																'Inscrições abertas' && (
																<div className="justify-center items-center gap-2.5 inline-flex px-2.5">
																	<div className="text-[#fff] font-bahnschrift text-[10px] flex items-center gap-0.5">
																		Participe
																		com{' '}
																		{showWalletAndBalance() ? (
																			'R$ '
																		) : (
																			<FutProIcon
																				style="w-2.5 h-2.5"
																				color="#ffffff"
																			/>
																		)}
																		{CurrencyFormatter(
																			Number(
																				tabsTitle.entryAmt
																			)
																		)}
																	</div>
																</div>
															)}
															{TournamentTypeDisplay[
																tournamentStatus!
															] !==
																'Cancelado' && (
																<div className="flex gap-1.5 px-2.5">
																	<div className="flex items-center justify-center gap-0.5">
																		<UserGroup color="#D1D1D1" />
																		<span className="text-[#fff] font-bahnschrift text-[10px]">
																			{Number(
																				tabsTitle.registeredEnrolls
																			)}
																		</span>
																	</div>
																	<div className="flex items-center justify-center gap-1">
																		<Min color="#D1D1D1" />
																		<span className="text-[#fff] font-bahnschrift text-[10px] flex items-center">
																			{Number(
																				tabsTitle.entryMinQtd
																			)}
																		</span>
																	</div>
																	<div className="flex items-center justify-center gap-1">
																		<Max color="#D1D1D1" />
																		<span className="text-[#fff] font-bahnschrift text-[10px] flex items-center">
																			{Number(
																				tabsTitle.entryMaxQtd
																			)}
																		</span>
																	</div>
																</div>
															)}
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							)}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
});

export default Carousel;
