import { App } from '@capacitor/app';
import { RiCloseFill } from '@remixicon/react';
import { useEffect } from 'react';
import { toggleScroll } from '../../../helpers/utils';

type ModalProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	outsideClick?: boolean;
	children: React.ReactNode;
};

const ModalEnroll = ({
	open,
	onClose,
	children,
	id,
	outsideClick = false,
}: ModalProps) => {
	useEffect(() => {
		// toggleScroll(open);
		App.addListener('backButton', (event) => {
			if (open) {
				onClose();
			}
		});

		return () => document.body.classList.remove('overflow-hidden');
	}, [open]);

	return (
		<div
			id={id}
			className={`fixed inset-0 flex justify-center items-center transition-colors z-50 ${
				open ? 'visible bg-custom-gray1/70' : 'invisible'
			}`}
			onClick={(e) => {
				e.stopPropagation();
				if (outsideClick) {
					onClose();
				}
			}}
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className={`flex flex-col w-full lg:max-w-96 lg:mx-auto mx-4 bg-custom-white rounded-xl shadow p-6 transition-all ${
					open ? 'scale-100 opacity-100' : 'scale-125 opacity-0'
				}`}
			>
				<button className="flex flex-grow-0 justify-end cursor-default">
					<RiCloseFill onClick={onClose} className="cursor-pointer" />
				</button>
				{children}
			</div>
		</div>
	);
};

export default ModalEnroll;
